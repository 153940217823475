import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'widget',
        loadChildren: () =>
            import('./widget/widget.module').then(m => m.WidgetModule),
    },
    {
        path: 'shsp',
        loadChildren: () =>
            import('./shsp/shsp.module').then(m => m.SHSPModule),
    },
    {
        path: 'authentication',
        loadChildren: () =>
            import('./authentication/authentication.module').then(
                m => m.AuthenticationModule
            ),
    },
    {
        path: '',
        redirectTo: 'authentication',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
