import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'isNullOrEmpty'
})
export class IsNullOrEmptyPipe implements PipeTransform {
    constructor() {}

    transform(value: any): any {
        debugger;

        return value && Object.keys(value).length === 0;
    }
}
