import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-close-button',
    templateUrl: './close-button.component.html',
    styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent implements OnInit {
    @Input() isDisabled = false;

    @Output() closeEventEmitter = new EventEmitter();

    constructor() {}

    ngOnInit() {
        // do nothing
    }
}
