export enum ValueType {
    None = 0,
    ActionStep,
    ActionStepAndUserMappings,
    ActionStepID,
    ActionStepIDs,
    ActionStepStatusIDsAndCompletionYear,
    ActionStepStatusIDAndCompletionYear,
    ActionSteps,
    ActionStepStatusID,
    ActionStepStatusIDs,
    Agency,
    AgencyID,
    AgencyIDAndChallengeAreaIDs,
    AgencyIDAndCompletionYear,
    AgencyIDAndKeyAreaIDs,
    AppRouteID,
    CompletionYear,
    CriticalEmphasisArea,
    CriticalEmphasisAreas,
    CurrentSession,
    DataColumnIDs,
    DataItems,
    DataTableAndColumnMapping,
    DataTableDefinition,
    DataTableDefinitionID,
    DataTableDefinitionName,
    Date,
    FileIDs,
    KeyArea,
    KeyAreaID,
    KeyAreas,
    KeyAreasSearchManyBody,
    ReportDefinition,
    SearchManyParams,
    Session,
    SessionID,
    Strategies,
    Strategy,
    User,
    UserID,
}
