import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ICriticalEmphasisAreaAndUserMapping } from '@models/critical-emphasis-area-and-user-mapping.model';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICriticalEmphasisArea } from '../_models/critical-emphasis-area.model';
import { IUser } from '../_models/user.model';
import { ApiService, ICallPostOptions } from './api-service.service';
import { CacheService } from './cache.service';

export interface LoadAllVMsOptions {
    includeCoLeadUserVMs: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CriticalEmphasisAreaAndUserMappingService extends ApiService<
    ICriticalEmphasisAreaAndUserMapping
> {
    usersObseravle: Observable<IUser[]>;

    private usersBS: BehaviorSubject<IUser[]>;

    constructor(
        authService: AuthenticationService,
        cacheService: CacheService,
        httpClient: HttpClient
    ) {
        super(
            'CriticalEmphasisAreaAndUserMappings',
            authService,
            cacheService,
            httpClient
        );

        this.usersBS = new BehaviorSubject([]) as BehaviorSubject<IUser[]>;
        this.usersObseravle = this.usersBS.asObservable();
    }

    loadCriticalEmphasisAreaAndUserMappingModelsForCriticalEmphasisAreaID(
        keyAreaID: string
    ) {
        const currentSession = null;
        // const currentSession = this.authService.currentSessionValue;
        if (currentSession) {
            const headers = {
                authorization: `khAuth {${currentSession.ID}}`,
            };

            const postOptions = {
                headers,
            };

            debugger;
            const observable = this.httpClient.post<
                ICriticalEmphasisAreaAndUserMapping[]
            >(
                `${this.apiURL}/FindManyForCriticalEmphasisAreaIDs`,
                [keyAreaID],
                postOptions
            );

            return observable;
        } else {
            // do nothing
        }
    }

    loadForCriticalEmphasisAreaIDs(
        keyAreaIDs: string[],
        options?: { bypassLoaded: boolean; callPostOptions: ICallPostOptions }
    ) {
        return this.callPost$(
            `FindManyForCriticalEmphasisAreaIDs`,
            keyAreaIDs,
            options && options.callPostOptions
        );
    }

    loadForCriticalEmphasisAreas(
        criticalEmphasisAreas: ICriticalEmphasisArea[],
        options?: {
            loadForCriticalEmphasisAreaIDs: {
                bypassLoaded: boolean;
                callPostOptions: ICallPostOptions;
            };
        }
    ) {
        const criticalEmphasisAreaIDs = _.map(
            criticalEmphasisAreas,
            ca => ca.ID
        );
        return this.loadForCriticalEmphasisAreaIDs(
            criticalEmphasisAreaIDs,
            options && options.loadForCriticalEmphasisAreaIDs
        );
    }

    loadCoLeadCriticalEmphasisAreaAndUserMappingModelsForCriticalEmphasisAreaID(
        keyAreaID: string
    ) {
        return this.callPost$(`FindManyCoLeadsForCriticalEmphasisAreaIDs`, [
            keyAreaID,
        ]);
    }

    loadCoLeadCriticalEmphasisAreaAndUserMappingModelsForUserID(
        userID: string
    ): Observable<ICriticalEmphasisAreaAndUserMapping[]> {
        const relativeURL = `FindManyCoLeadsForUserID/${userID}`;
        return this.callGet$(relativeURL);
    }
}
