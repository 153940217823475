import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ApiService } from './api-service.service';
import { IMenuItem } from '@models/menu-item.model';
import { map, catchError } from 'rxjs/operators';

import * as _ from 'lodash';
import { CacheService } from './cache.service';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MenuItemService extends ApiService<IMenuItem> {
    constructor(
        authService: AuthenticationService,
        cacheService: CacheService,
        httpClient: HttpClient
    ) {
        super('MenuItems', authService, cacheService, httpClient);
    }

    loadAllForLeftSidenav() {
        return this.callGet$('ForLeftSidenav').pipe(
            map(menuItems => _.orderBy(menuItems, 'SortOrder')),
            catchError(err => {
                debugger;
                console.log('Handling error locally and rethrowing it...', err);
                return throwError(err);
            })
        );
    }
}
