import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, flatMap } from 'rxjs/operators';

import { ISession } from '@auth/_models/session';
import { environment } from '../../environments/environment';
import { UserGroupService } from '@app/shsp/_services/user-group.service';
import { CacheService } from '@app/shsp/_services/cache.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    headers: HttpHeaders;

    isAdmin = false;
    isLoggedIn = false;
    userID: string;
    sessionID: string;

    public currentSession$ = of<ISession>();

    constructor(private cacheService: CacheService, private http: HttpClient) {
        const storedSession = JSON.parse(localStorage.getItem('currentUser'));
        if (storedSession) {
            this.headers = new HttpHeaders({
                authorization: `khAuth {${storedSession.ID}}`,
            });

            this.isLoggedIn = true;
            this.sessionID = storedSession.ID;
            this.userID = storedSession.UserID;
        }
    }

    login(username, password) {
        return this.http
            .post<ISession>(`${environment.api.baseURL}/security/login`, {
                username,
                password,
            })
            .pipe(
                tap(session => {
                    if (session) {
                        this.cacheService.invalidateApiCacheMapT();

                        this.headers = new HttpHeaders({
                            authorization: `khAuth {${session.ID}}`,
                        });

                        this.isLoggedIn = true;
                        this.sessionID = session.ID;
                        this.userID = session.UserID;

                        localStorage.setItem(
                            'currentUser',
                            JSON.stringify(session)
                        );
                    }
                })
            );
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        this.currentSession$ = of<ISession>();
        this.headers = null;
    }

    resetPassword(password: string, sessionID: string) {
        const urlString = environment.api.baseURL + "/security/ResetPassword/" + sessionID + "/" + password;
        return this.http
        .post<ISession>(`${environment.api.baseURL}/security/ResetPassword`, {
            sessionID,
            password,
        })
    }

    sendResetPasswordEmail(email: string) {
        return this.http.post(
            `${environment.api.baseURL}/security/${email}/SendResetPasswordEmail`,
            null
        );
    }
}
