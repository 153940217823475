import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueType } from './_enums/value-type.enum';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    exports: [
        // enums
    ],
})
export class SHSPCoreModule {
    static forRoot(): ModuleWithProviders<SHSPCoreModule> {
        return {
            ngModule: SHSPCoreModule,
            providers: [],
        };
    }
}
