import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication/authentication.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, delay, flatMap, shareReplay, tap } from 'rxjs/operators';
import { IActionStep } from '../_models/action-step.model';
import { ICriticalEmphasisArea } from '../_models/critical-emphasis-area.model';
import { IStrategyAndFileMapping } from '../_models/strategy-and-file-mapping.model';
import { IStrategyAndUserMapping } from '../_models/strategy-and-user-mapping.model';
import { IStrategy } from '../_models/strategy.model';
import { ApiService, ICallGetOptions } from './api-service.service';
import { CacheService } from './cache.service';
import { StrategyAndUserMappingService } from './strategy-and-user-mapping.service';
import { UserService } from './user.service';

export interface ILoadStrategyAndFileMappingsForStrategyIDOptions {
    callGetOptions: ICallGetOptions;
    timeout: number;
}

@Injectable({
    providedIn: 'root',
})
export class StrategyService extends ApiService<IStrategy> {
    apiCacheMap = new Map<any, Observable<IStrategy[]>>();

    criticalEmphasisAreaVMs: BehaviorSubject<IStrategy[]>;

    constructor(
        authService: AuthenticationService,
        cacheService: CacheService,
        httpClient: HttpClient,
        private criticalEmphasisAreaAndUserMappingService: StrategyAndUserMappingService,
        private userService: UserService
    ) {
        super('Strategies', authService, cacheService, httpClient);

        // this.dataStore = { criticalEmphasisAreaVMs: [] };

        this.criticalEmphasisAreaVMs = new BehaviorSubject(
            []
        ) as BehaviorSubject<IStrategy[]>;
    }

    createActionStepForStrategyID$(
        strategyID: string,
        actionStep: IActionStep
    ) {
        const relativeURL = `${strategyID}/CreateActionStep`;
        const body = actionStep;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$<IStrategyAndUserMapping>(
            relativeURL,
            body,
            callOptions
        );

        return response$.pipe(
            tap(res => {
                this.criticalEmphasisAreaAndUserMappingService.invalidateApiCacheMapT();
            })
        );
    }

    loadActionStepsForStrategyID$(strategyID: string) {
        return this.callGet$(`${strategyID}/ActionSteps`) as Observable<
            IActionStep[]
        >;
    }

    loadStrategyAndUserMappingsForStrategyID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<IStrategyAndUserMapping[]>(
            `${criticalEmphasisAreaID}/StrategyAndUserMappings`,
            options && options.callGetOptions
        ).pipe(shareReplay(1));
    }

    removeFileForStrategyID(criticalEmphasisAreaID: string, fileID: string) {
        const relativeURL = `${criticalEmphasisAreaID}/Files/${fileID}/Remove`;
        return super.callPost$<number>(relativeURL);
    }

    loadStrategyAndFileMappingsForStrategyID$(
        criticalEmphasisAreaID: string,
        options?: ILoadStrategyAndFileMappingsForStrategyIDOptions
    ): Observable<IStrategyAndFileMapping[]> {
        const relativeURL = `${criticalEmphasisAreaID}/StrategyAndFileMappings`;
        const delayTimeout = options && options.timeout ? options.timeout : 0;
        return super
            .callGet$<IStrategyAndFileMapping[]>(
                relativeURL,
                options && options.callGetOptions
            )
            .pipe(delay(delayTimeout));
    }

    loadStrategiesForStrategy(
        criticalEmphasisArea: IStrategy,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.loadStrategiesForStrategyID(criticalEmphasisArea.ID);
    }

    loadStrategiesForStrategyID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<IStrategy[]>(
            `${criticalEmphasisAreaID}/Strategies`,
            options && options.callGetOptions
        ).pipe(shareReplay(1));
    }

    loadStrategyModelForID(id: string) {
        // const currentSession = this.authService.currentSessionValue;
        const currentSession = null;
        if (currentSession) {
            const headers = {
                authorization: `khAuth {${currentSession.ID}}`,
            };

            const getOptions = {
                headers,
            };

            return this.httpClient.get<IStrategy>(
                `${this.apiURL}/${id}`,
                getOptions
            );
        } else {
            // do nothing
        }
    }

    loadAllStrategyModels() {
        debugger;
        // const currentSession = this.authService.currentSessionValue;
        const currentSession = null;
        if (currentSession) {
            const headers = {
                authorization: `khAuth ${currentSession.ID}`,
            };

            const getOptions = {
                headers,
            };

            const observable = this.httpClient
                .get<IStrategy[]>(`${this.apiURL}`, getOptions)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        let data = {};
                        data = {
                            reason:
                                error && error.error.reason
                                    ? error.error.reason
                                    : '',
                            status: error.status,
                        };

                        return throwError(error);
                    })
                );

            return observable;
        }
    }

    loadCoLeadUserIDsForStrategyID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<IStrategyAndUserMapping[]>(
            `${criticalEmphasisAreaID}/CoLeadUserIDs`,
            options && options.callGetOptions
        ).pipe(shareReplay(1));
    }

    loadCoLeadUsersForStrategyID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.loadCoLeadUserIDsForStrategyID(criticalEmphasisAreaID).pipe(
            flatMap(userIDs => {
                return this.userService.loadForIDs$(userIDs);
            })
        );
    }

    loadMemberUserIDsForStrategyID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<IStrategyAndUserMapping[]>(
            `${criticalEmphasisAreaID}/MemberUserIDs`,
            options && options.callGetOptions
        ).pipe(shareReplay(1));
    }

    loadMemberUsersForStrategyID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.loadMemberUserIDsForStrategyID(criticalEmphasisAreaID).pipe(
            flatMap(userIDs => {
                return this.userService.loadForIDs$(userIDs);
            })
        );
    }

    loadForActionStepStatusIDsAndCompletionYear(
        actionStepStatusIDs: string[],
        completionYear: string
    ) {
        let relativeURL = '';

        if (actionStepStatusIDs && actionStepStatusIDs.length) {
            relativeURL += `ActionStepStatus/${actionStepStatusIDs}/`;
        }

        if (completionYear) {
            relativeURL += `CompletionYear/${completionYear}`;
        }

        return super.callGet$<IStrategy[]>(relativeURL);
    }

    loadForActionStepStatusIDAndCompletionYear(
        actionStepStatusID: string,
        completionYear: string
    ) {
        let relativeURL = '';

        if (actionStepStatusID) {
            relativeURL += `ActionStepStatus/${actionStepStatusID}/`;
        }

        if (completionYear) {
            relativeURL += `CompletionYear/${completionYear}`;
        }

        return super.callGet$<IStrategy[]>(relativeURL);
    }

    loadForAgencyIDAndStrategyIDs(
        agencyID: string,
        criticalEmphasisAreaIDs: string[]
    ) {
        const relativeURL = 'SearchMany';
        const body = {
            AgencyID: agencyID,
            StrategyIDs: criticalEmphasisAreaIDs,
        };

        return super.callPost$<IStrategy[]>(relativeURL, body);
    }

    loadForAgencyIDAndCompletionYear(agencyID: string, completionYear: string) {
        let relativeURL = '';

        if (agencyID) {
            relativeURL += `Agency/${agencyID}/`;
        }

        if (completionYear) {
            relativeURL += `CompletionYear/${completionYear}`;
        }

        return super.callGet$<IStrategy[]>(relativeURL);
    }

    loadForBody$(body: {
        ActionStepIDs?: string[];
        ActionStepStatusIDs?: string[];
        AgencyID?: string;
        CompletionYear?: string;
        CriticalEmphasisAreaIDs?: string[];
    }) {
        if (body) {
            const relativeURL = `SearchMany`;
            return this.callPost$<IStrategy[]>(relativeURL, body);
        } else {
            return of<IStrategy[]>([]);
        }
    }

    loadForCriticalEmphasisAreas$(
        criticalEmphasisAreas: ICriticalEmphasisArea[]
    ) {
        const criticalEmphasisAreaIDs = _.map(
            criticalEmphasisAreas,
            cea => cea.ID
        );
        return this.loadForCriticalEmphasisAreaIDs$(criticalEmphasisAreaIDs);
    }

    loadForCriticalEmphasisAreaIDs$(criticalEmphasisAreaIDs: string[]) {
        return this.callPost$('SearchMany', {
            CriticalEmphasisAreaIDs: criticalEmphasisAreaIDs,
        }) as Observable<IStrategy[]>;
    }

    loadForCompletionYear$(completionYear: string) {
        let relativeURL = '';

        debugger;
        if (completionYear) {
            relativeURL += `CompletionYear/${completionYear}`;
        }

        return this.callGet$<IStrategy[]>(relativeURL);
    }

    setCoLeadUsersForStrategyID(
        criticalEmphasisAreaID: string,
        coLeadUserIDs: string[]
    ) {
        const relativeURL = `${criticalEmphasisAreaID}/SetCoLeadUsers`;
        const body = coLeadUserIDs;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$<IStrategyAndUserMapping>(
            relativeURL,
            body,
            callOptions
        );

        return response$.pipe(
            tap(res => {
                this.criticalEmphasisAreaAndUserMappingService.invalidateApiCacheMapT();
            })
        );
    }

    setMemberUsersForStrategyID(
        criticalEmphasisAreaID: string,
        coLeadUserIDs: string[]
    ) {
        const relativeURL = `${criticalEmphasisAreaID}/SetMemberUsers`;
        const body = coLeadUserIDs;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$<IStrategyAndUserMapping>(
            relativeURL,
            body,
            callOptions
        );

        return response$.pipe(
            tap(res => {
                this.criticalEmphasisAreaAndUserMappingService.invalidateApiCacheMapT();
            })
        );
    }

    toggleIsFavoriteForStrategyID$(criticalEmphasisAreaID: string) {
        const relativeURL = `${criticalEmphasisAreaID}/ToggleIsFavorite`;
        const body = null;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$<IStrategyAndUserMapping>(
            relativeURL,
            body,
            callOptions
        );

        return response$.pipe(
            tap(res => {
                this.criticalEmphasisAreaAndUserMappingService.invalidateApiCacheMapT();
            })
        );
    }
}
