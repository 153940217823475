import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { IFile } from '@models/file.model';
import { IMilestone } from '@models/milestone.model';
import { IStrategy } from '@models/strategy.model';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, flatMap, map, mergeMap } from 'rxjs/operators';
import { IActionStepAndFileMapping } from '../_models/action-step-and-file-mapping.model';
import { IActionStepAndUserMapping } from '../_models/action-step-and-user-mapping.model';
import { IActionStepHistory } from '../_models/action-step-history.model';
import { IActionStep } from '../_models/action-step.model';
import { IKeyArea } from '../_models/key-area.model';
import { ActionStepHistoryService } from './action-step-history.service';
import { ApiService, ICallGetOptions } from './api-service.service';
import { CacheService } from './cache.service';
import { CriticalEmphasisAreaService } from './critical-emphasis-area.service';
import { StrategyService } from './strategy.service';

export interface ILoadActionStepAndFileMappingsForActionStepIDOptions {
    callGetOptions: ICallGetOptions;
    timeout: number;
}

@Injectable({
    providedIn: 'root',
})
export class ActionStepService extends ApiService<IActionStep> {
    actionsObseravle: Observable<IActionStep[]>;
    apiCacheMap = new Map<any, Observable<IActionStep[]>>();

    private actionsBS: BehaviorSubject<IActionStep[]>;

    constructor(
        private actionStepHistoryService: ActionStepHistoryService,
        protected authService: AuthenticationService,
        protected cacheService: CacheService,
        private criticalEmphasisAreaService: CriticalEmphasisAreaService,
        protected http: HttpClient,
        private strategyService: StrategyService
    ) {
        super('ActionSteps', authService, cacheService, http);

        this.actionsBS = new BehaviorSubject([]) as BehaviorSubject<
            IActionStep[]
        >;
        this.actionsObseravle = this.actionsBS.asObservable();
    }

    addLeadersForActionStepID$(actionStepID: string, userIDs: string[]) {
        const relativeURL = `${actionStepID}/AddLeaders`;
        const body = userIDs;
        const callOptions = { bypassLoaded: true };
        return this.callPostSingle$<IActionStepAndUserMapping>(
            relativeURL,
            body,
            callOptions
        );
    }

    createPerformanceMeasureForActionStepIDAndComment(
        actionID: string,
        comment: string
    ): Observable<IMilestone> {
        const relativeURL = `${actionID}/Milestones/AddComment`;
        return this.callPostSingle$<IActionStep>(relativeURL, { comment });
    }

    loadActionFileModelsForActionID(actionID: string) {
        return this.callGet$<IFile[]>(`${actionID}/Files`);
    }

    loadActionStepAndFileMappingsForActionStepID$(
        actionStepID: string,
        options?: ILoadActionStepAndFileMappingsForActionStepIDOptions
    ): Observable<IActionStepAndFileMapping[]> {
        const relativeURL = `${actionStepID}/ActionStepAndFileMappings`;
        const delayTimeout = options && options.timeout ? options.timeout : 0;
        return super
            .callGet$<IActionStepAndFileMapping[]>(
                relativeURL,
                options && options.callGetOptions
            )
            .pipe(delay(delayTimeout));
    }

    loadActionStepHistoriesForActionID$(actionID: string) {
        return this.callGet2$<IActionStepHistory[]>(
            `${actionID}/ActionStepHistories`
        );
    }

    // loadActionStepHistoriesForActionIDs$(actionIDs: string[]) {
    //     debugger;
    //     //const actionID = actionIDs[0];
    //     return this.callGet2$<IActionStepHistory[]>(
    //         `${actionID}/ActionStepHistories`
    //     );
    // }

    loadActionStepMilestoneModelsForActionID(
        actionStepID: string,
        options?: { callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<IMilestone[]>(
            `${actionStepID}/Milestones`,
            options && options.callGetOptions
        );
    }

    loadForKeyAreas$(keyAreas: IKeyArea[]) {
        return this.criticalEmphasisAreaService.loadForKeyAreas$(keyAreas).pipe(
            mergeMap(criticalEmphasisAreas => {
                return this.strategyService
                    .loadForCriticalEmphasisAreas$(criticalEmphasisAreas)
                    .pipe(
                        mergeMap(strategies => {
                            return this.loadForStrategies$(strategies);
                        })
                    );
            })
        );
    }

    loadActionStepsForStrategyIDs(strategyIDs: string[]) {
        debugger;
        return null;
    }

    loadActionStepsForStrategy(strategy: IStrategy) {
        if (strategy) {
            debugger;
            return null;
        }

        return of<IActionStep[]>([]);
    }

    loadActionStepsGroupedByKeyAreaIDsForKeyAreas(keyAreas: IKeyArea[]) {
        return this.criticalEmphasisAreaService.loadForKeyAreas$(keyAreas).pipe(
            flatMap(criticalEmphasisAreas => {
                return this.strategyService
                    .loadForCriticalEmphasisAreas$(criticalEmphasisAreas)
                    .pipe(
                        mergeMap(strategies => {
                            return this.loadForStrategies$(strategies).pipe(
                                map(actionSteps => {
                                    const criticalEmphasisAreasByKeyAreaID = _.groupBy(
                                        criticalEmphasisAreas,
                                        'KeyAreaID'
                                    );

                                    const groups = _.chain(keyAreas)
                                        .map(ka => {
                                            const criticalEmphasisAreas =
                                                criticalEmphasisAreasByKeyAreaID[
                                                    ka.ID
                                                ];

                                            const criticalEmphasisAreaIDs = _.map(
                                                criticalEmphasisAreas,
                                                cea => cea.ID
                                            );

                                            const keyAreaStrategies = _.filter(
                                                strategies,
                                                s =>
                                                    criticalEmphasisAreaIDs.includes(
                                                        s.CriticalEmphasisAreaID
                                                    )
                                            );

                                            const keyAreaStrategyIDs = _.map(
                                                keyAreaStrategies,
                                                kas => kas.ID
                                            );

                                            const keyAreaActionSteps = _.filter(
                                                actionSteps,
                                                actionStep => {
                                                    return keyAreaStrategyIDs.includes(
                                                        actionStep.StrategyID
                                                    );
                                                }
                                            );

                                            return {
                                                actionSteps: keyAreaActionSteps,
                                                keyAreaID: ka.ID,
                                            };
                                        })
                                        .keyBy(g => g.keyAreaID)
                                        .mapValues(g => g.actionSteps)
                                        .value();

                                    return groups;
                                })
                            );
                        })
                    );
            })
        );
    }

    loadActionStepsGroupedByStrategyIDsForStrategies(strategies: IStrategy[]) {
        return this.loadForStrategies$(strategies).pipe(
            map(actionSteps => {
                debugger;
                const mappingsByActionID = _.keyBy(
                    actionSteps,
                    actionStep => actionStep.StrategyID
                );
                const groups = _.groupBy(actionSteps, action => {
                    return mappingsByActionID[action.ID].StrategyID;
                });

                const allStrategyIDs = _.map(strategies, 'ID');

                // handle missing key Areas
                const mappingStrategyIDs = _.keys(groups);
                const missingStrategyIDs = _.differenceBy(
                    allStrategyIDs,
                    mappingStrategyIDs
                );
                if (missingStrategyIDs.length) {
                    _.every(
                        missingStrategyIDs,
                        strategyID => (groups[strategyID] = [])
                    );
                }

                return groups;
            })
        );
    }

    loadActionStepsGroupedByStrategyIDsForStrategiesAndActionStepStatusID(
        strategies: IStrategy[],
        actionStepStatusID: string
    ) {
        return this.loadForStrategies$(strategies).pipe(
            flatMap(actionSteps => {
                // return null;
                debugger;
                return of([]);
                // return this.loadForActionStepsAndActionStepStatusID(
                //     actionSteps,
                //     actionStepStatusID
                // ).pipe(
                //     map(actions => {
                //         return _.groupBy(actionSteps, 'ActionStepStatusID');
                //     })
                // );
            })
        );
    }

    loadForActionStepIDsAndActionStepStatusIDsAndAgencyIDAndCompletionYear(
        actionIDs: string[],
        actionStepStatusIDs: string[],
        agencyID: string,
        completionYear: string
    ) {
        if (actionIDs && actionIDs.length) {
            return this.loadForIDsAndActionStepStatusIDsAndAgencyIDAndCompletionYear(
                actionIDs,
                actionStepStatusIDs,
                agencyID,
                completionYear
            );
        }

        return of<IActionStep[]>([]);
    }

    loadForKeyAreasAndActionStepStatusID(
        keyAreas: IKeyArea[],
        actionStepStatusID: string
    ) {
        debugger;
        return of([]);
    }

    loadForStrategy$(strategy: IStrategy): Observable<IActionStep[]> {
        if (strategy) {
            return this.loadForStrategyID$(strategy.ID);
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForStrategyID$(strategyID: string): Observable<IActionStep[]> {
        if (strategyID) {
            return this.loadForBody({ StrategyIDs: [strategyID] });
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndActionStepStatusIDAndCompletionYear$(
        strategies: IStrategy[],
        actionStepStatusID: string,
        completionYear: string
    ) {
        if (strategies && strategies.length) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategies(strategies)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDAndCompletionYear(
            //                 mappings,
            //                 actionStepStatusID,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndActionStepStatusIDsAndCompletionYear$(
        strategies: IStrategy[],
        actionStepStatusIDs: string[],
        completionYear: string
    ) {
        if (strategies && strategies.length) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategies(strategies)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDsAndCompletionYear(
            //                 mappings,
            //                 actionStepStatusIDs,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndActionStepStatusIDAndAgencyIDAndCompletionYear$(
        strategies: IStrategy[],
        actionStepStatusID: string,
        agencyID: string,
        completionYear: string
    ) {
        debugger;
        if (strategies && strategies.length) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategies(strategies)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDAndAgencyIDAndCompletionYear(
            //                 mappings,
            //                 actionStepStatusID,
            //                 agencyID,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndActionStepStatusIDsAndAgencyIDAndCompletionYear$(
        strategies: IStrategy[],
        actionStepStatusIDs: string[],
        agencyID: string,
        completionYear: string
    ) {
        if (strategies && strategies.length) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategies(strategies)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDsAndAgencyIDAndCompletionYear(
            //                 mappings,
            //                 actionStepStatusIDs,
            //                 agencyID,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndActionStepStatusIDsAndAgencyID$(
        strategies: IStrategy[],
        actionStepStatusIDs: string[],
        agencyID: string
    ) {
        if (strategies && strategies.length) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategies(strategies)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDsAndAgencyID(
            //                 mappings,
            //                 actionStepStatusIDs,
            //                 agencyID
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndAgencyIDAndCompletionYear$(
        strategies: IStrategy[],
        agencyID: string,
        completionYear: string
    ) {
        if (strategies && strategies.length) {
            const strategyIDs = _.map(strategies, s => s.ID);
            return this.loadForBody({
                AgencyID: agencyID,
                CompletionYear: completionYear,
                StrategyIDs: strategyIDs,
            });
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndAgencyID$(strategies: IStrategy[], agencyID: string) {
        if (strategies && strategies.length) {
            const strategyIDs = _.map(strategies, s => s.ID);
            return this.loadForBody({
                AgencyID: agencyID,
                StrategyIDs: strategyIDs,
            });
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndAgencyIDAndCompletionYear$(
        strategy: IStrategy,
        agencyID: string,
        completionYear: string
    ) {
        if (strategy) {
            return this.loadForBody({
                AgencyID: agencyID,
                CompletionYear: completionYear,
                StrategyIDs: [strategy.ID],
            });
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndActionStepStatusID$(
        strategy: IStrategy,
        actionStepStatusID: string
    ): Observable<IActionStep[]> {
        if (strategy) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategy$(strategy)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusID(
            //                 mappings,
            //                 actionStepStatusID
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndActionStepStatusIDs$(
        strategy: IStrategy,
        actionStepStatusIDs: string[]
    ): Observable<IActionStep[]> {
        if (strategy) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategy$(strategy)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDs(
            //                 mappings,
            //                 actionStepStatusIDs
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndActionStepStatusIDAndCompletionYear$(
        strategy: IStrategy,
        actionStepStatusID: string,
        completionYear: string
    ): Observable<IActionStep[]> {
        if (strategy) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategy$(strategy)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDAndCompletionYear(
            //                 mappings,
            //                 actionStepStatusID,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndActionStepStatusIDsAndCompletionYear$(
        strategy: IStrategy,
        actionStepStatusIDs: string[],
        completionYear: string
    ): Observable<IActionStep[]> {
        if (strategy) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategy$(strategy)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDsAndCompletionYear(
            //                 mappings,
            //                 actionStepStatusIDs,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndActionStepStatusIDAndAgencyIDAndCompletionYear$(
        strategy: IStrategy,
        actionStepStatusID: string,
        agencyID: string,
        completionYear: string
    ): Observable<IActionStep[]> {
        if (strategy) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategy$(strategy)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDAndAgencyIDAndCompletionYear(
            //                 mappings,
            //                 actionStepStatusID,
            //                 agencyID,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndActionStepStatusIDsAndAgencyID$(
        strategy: IStrategy,
        actionStepStatusIDs: string[],
        agencyID: string
    ): Observable<IActionStep[]> {
        if (strategy) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategy$(strategy)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDsAndAgencyID(
            //                 mappings,
            //                 actionStepStatusIDs,
            //                 agencyID
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndActionStepStatusIDsAndAgencyIDAndCompletionYear$(
        strategy: IStrategy,
        actionStepStatusIDs: string[],
        agencyID: string,
        completionYear: string
    ): Observable<IActionStep[]> {
        if (strategy) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategy$(strategy)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDsAndAgencyIDAndCompletionYear(
            //                 mappings,
            //                 actionStepStatusIDs,
            //                 agencyID,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndAgencyID$(strategy: IStrategy, agencyID: string) {
        if (strategy) {
            return this.loadForBody({
                AgencyID: agencyID,
                StrategyIDs: [strategy.ID],
            });
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategyAndCompletionYear$(
        strategy: IStrategy,
        completionYear: string
    ): Observable<IActionStep[]> {
        if (strategy) {
            debugger;
            return of([]);
            // return this
            //     .loadForStrategy$(strategy)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndCompletionYear(
            //                 mappings,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForBody(body: {
        ActionStepIDs?: string[];
        ActionStepStatusIDs?: string[];
        AgencyID?: string;
        CompletionYear?: string;
        StrategyIDs?: string[];
        UserID?: string;
    }) {
        if (body) {
            const relativeURL = `SearchMany`;
            return this.callPost$<IActionStep[]>(
                relativeURL,
                body
            ) as Observable<IActionStep[]>;
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForStrategies$(strategies: IStrategy[]): Observable<IActionStep[]> {
        const strategyIDs = _.map(strategies, s => s.ID);
        return this.loadForStrategyIDs$(strategyIDs);
    }

    loadForStrategyIDs$(strategyIDs: string[]): Observable<IActionStep[]> {
        return this.loadForBody({ StrategyIDs: strategyIDs });
    }

    loadForStrategiesAndActionStepStatusID(
        strategies: IStrategy[],
        actionStepStatusID: string
    ): Observable<IActionStep[]> {
        if (strategies && strategies.length) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategies(strategies)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusID(
            //                 mappings,
            //                 actionStepStatusID
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndActionStepStatusIDs(
        strategies: IStrategy[],
        actionStepStatusIDs: string[]
    ): Observable<IActionStep[]> {
        if (strategies && strategies.length) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategies(strategies)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndActionStepStatusIDs(
            //                 mappings,
            //                 actionStepStatusIDs
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForStrategiesAndCompletionYear(
        strategies: IStrategy[],
        completionYear: string
    ) {
        if (strategies && strategies.length) {
            debugger;
            return of([]);
            // return this.actionStepAndStrategyMappingService
            //     .loadForStrategies(strategies)
            //     .pipe(
            //         flatMap(mappings => {
            //             return this.loadForActionStepAndStrategyMappingsAndCompletionYear(
            //                 mappings,
            //                 completionYear
            //             );
            //         })
            //     );
        }

        return of<IActionStep[]>([]);
    }

    loadForIDsAndActionStepStatusID(ids: string[], actionStepStatusID: string) {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            if (uniqIDs.length) {
                const relativeURL = `ActionStepStatus/${actionStepStatusID}/FindMany`;
                return this.callPost$<IActionStep[]>(relativeURL, uniqIDs);
            } else {
                return of<IActionStep[]>([]);
            }
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndActionStepStatusIDs(
        ids: string[],
        actionStepStatusIDs: string[]
    ) {
        if (ids && ids.length) {
            const body = {
                ActionStepIDs: _.chain(ids)
                    .compact()
                    .uniq()
                    .value(),
                ActionStepStatusIDs: actionStepStatusIDs,
            };

            return this.loadForBody(body);
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndAgencyID(ids: string[], agencyID: string) {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            if (uniqIDs.length) {
                const relativeURL = `Agency/${agencyID}/FindMany`;
                return this.callPost$<IActionStep[]>(relativeURL, uniqIDs);
            } else {
                return of<IActionStep[]>([]);
            }
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndActionStepStatusIDAndCompletionYear(
        ids: string[],
        actionStepStatusID: string,
        completionYear: string
    ) {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            if (uniqIDs.length) {
                const relativeURL = `ActionStepStatus/${actionStepStatusID}/CompletionYear/${completionYear}/FindManyForIDs`;
                return this.callPost$<IActionStep[]>(relativeURL, uniqIDs);
            } else {
                return of<IActionStep[]>([]);
            }
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndActionStepStatusIDsAndCompletionYear(
        ids: string[],
        actionStepStatusIDs: string[],
        completionYear: string
    ) {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            return this.loadForBody({
                ActionStepIDs: uniqIDs,
                ActionStepStatusIDs: actionStepStatusIDs,
                CompletionYear: completionYear,
            });
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndActionStepStatusIDAndAgencyIDAndCompletionYear(
        ids: string[],
        actionStepStatusID: string,
        agencyID: string,
        completionYear: string
    ) {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            if (uniqIDs.length) {
                const relativeURL = `ActionStepStatus/${actionStepStatusID}/Agency/${agencyID}/CompletionYear/${completionYear}/FindManyForIDs`;
                return this.callPost$<IActionStep[]>(relativeURL, uniqIDs);
            } else {
                return of<IActionStep[]>([]);
            }
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndActionStepStatusIDsAndAgencyIDAndCompletionYear(
        ids: string[],
        actionStepStatusIDs: string[],
        agencyID: string,
        completionYear: string
    ) {
        if (ids && ids.length) {
            const body = {
                ActionStepIDs: _.chain(ids)
                    .compact()
                    .uniq()
                    .value(),
                AgencyID: agencyID,
                ActionStepStatusIDs: actionStepStatusIDs,
                CompletionYear: completionYear,
            };

            return this.loadForBody(body);
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndActionStepStatusIDsAndAgencyID(
        ids: string[],
        actionStepStatusIDs: string[],
        agencyID: string
    ) {
        if (ids && ids.length) {
            const body = {
                ActionStepIDs: _.chain(ids)
                    .compact()
                    .uniq()
                    .value(),
                ActionStepStatusIDs: actionStepStatusIDs,
                AgencyID: agencyID,
            };

            return this.loadForBody(body);
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndActionStepStatusIDAndAgencyIDsAndCompletionYear(
        ids: string[],
        actionStepStatusID: string,
        agencyID: string,
        completionYear: string
    ) {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            if (uniqIDs.length) {
                debugger;
                const relativeURL = `ActionStepStatus/${actionStepStatusID}/Agency/${agencyID}/CompletionYear/${completionYear}/FindManyForIDs`;
                return this.callPost$<IActionStep[]>(relativeURL, uniqIDs);
            } else {
                return of<IActionStep[]>([]);
            }
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndAgencyIDAndCompletionYear(
        ids: string[],
        agencyID: string,
        completionYear: string
    ) {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            if (uniqIDs.length) {
                return this.loadForBody({
                    ActionStepIDs: uniqIDs,
                    AgencyID: agencyID,
                    CompletionYear: completionYear,
                });
            } else {
                return of<IActionStep[]>([]);
            }
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForIDsAndCompletionYear(ids: string[], completionYear: string) {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            if (uniqIDs.length) {
                const relativeURL = `CompletionYear/${completionYear}/FindManyForIDs`;
                return this.callPost$<IActionStep[]>(relativeURL, uniqIDs);
            } else {
                return of<IActionStep[]>([]);
            }
        } else {
            return of<IActionStep[]>([]);
        }
    }

    loadForLastStatusChangeMonth(month: number) {
        return this.actionStepHistoryService
            .loadForLastStatusChangeYear(month)
            .pipe(
                flatMap(actionHistories => {
                    const actionIDs = _.map(actionHistories, 'ActionID');
                    return this.loadForIDs$(actionIDs);
                })
            );
    }

    loadForLastStatusChangeYear(year: number) {
        return this.actionStepHistoryService
            .loadForLastStatusChangeYear(year)
            .pipe(
                flatMap(actionHistories => {
                    const actionIDs = _.map(actionHistories, 'ActionID');
                    return this.loadForIDs$(actionIDs);
                })
            );
    }

    removeLeaderForActionStepID$(actionStepID, userID) {
        return this.callPost$(`${actionStepID}/RemoveLeader/${userID}`);
    }

    setLeaderUsersForActionStepID(
        actionStepID: string,
        leaderUserIDs: string[]
    ) {
        const relativeURL = `${actionStepID}/SetLeaderUsers`;
        const body = leaderUserIDs;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$<IActionStepAndUserMapping>(
            relativeURL,
            body,
            callOptions
        );

        return response$;
    }

    updateActionStep(
        actionID: string,
        actionStep: IActionStep
    ): Observable<IActionStep> {
        const relativeURL = `${actionID}/Update`;
        return this.callPostSingle$<IActionStep>(relativeURL, actionStep);
    }

    updateActionStepStatusIDAndComment(
        actionID: string,
        actionStepStatusID: string,
        comment: string
    ): Observable<IActionStep> {
        const relativeURL = `${actionID}/UpdateActionStepStatus`;
        const body = {
            ActionStepStatusID: actionStepStatusID,
            Comment: comment,
        };
        return this.callPostSingle$<IActionStep>(relativeURL, body);
    }

    updateActionStepStatusIDCommentAndEndDate(
        actionID: string,
        actionStepStatusID: string,
        comment: string,
        endDate: string
    ): Observable<IActionStep> {
        const relativeURL = `${actionID}/UpdateActionStepStatus`;
        const body = {
            ActionStepStatusID: actionStepStatusID,
            Comment: comment,
            CompletionEst: endDate,
        };
        return this.callPostSingle$<IActionStep>(relativeURL, body);
    }
}
