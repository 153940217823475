// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    api: {
        // baseURL: 'http://localhost:64041/api',  
        baseURL: 'https://app-adotsafetytools-dev-scus.azurewebsites.net/api',
    },

    config: {
        appName: 'Arizona SHSP Action Step Tracking Tool',
    },
    // auth: {
    //     URL: 'https://local.api.shsp.com/api/security/login',
    // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
