import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ApiService } from './api-service.service';
import { IUser } from '../_models/user.model';
import { CacheService } from './cache.service';

import * as _ from 'lodash';
import { IUserAndUserGroupMapping } from '../_models/user-and-user-group-mapping.model';

@Injectable({
    providedIn: 'root',
})
export class UserAndUserGroupMappingService extends ApiService<
    IUserAndUserGroupMapping
> {
    constructor(
        protected authService: AuthenticationService,
        protected cacheService: CacheService,
        protected http: HttpClient
    ) {
        super('UserAndUserGroupMappings', authService, cacheService, http);
    }
}
