import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISession } from '@auth/_models/session';
import { ApiService } from '@app/shsp/_services/api-service.service';
import { AuthenticationService } from '../authentication.service';
import { CacheService } from '@app/shsp/_services/cache.service';

@Injectable({ providedIn: 'root' })
export class SessionService extends ApiService<ISession> {
    constructor(
        protected authService: AuthenticationService,
        protected cacheService: CacheService,
        protected httpClient: HttpClient
    ) {
        super('Sessions', authService, cacheService, httpClient);
    }

    // getAll() {
    //     return this.httpClient.get<ISession[]>(`${config.apiUrl}/users`);
    // }

    // register(user: ISession) {
    //     return this.httpClient.post(`${config.apiUrl}/users/register`, user);
    // }

    // delete(id: number) {
    //     return this.httpClient.delete(`${config.apiUrl}/users/${id}`);
    // }
}
