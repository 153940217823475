import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DynamicScriptLoaderService } from './dynamic-script-loader-service.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { LearnMoreDialogComponent } from './shsp/components/dialogs/learn-more-dialog/learn-more-dialog.component';
// import { NgPipesModule } from 'ngx-pipes';
import { MenuItemsListComponent } from './shsp/components/lists/menu-items-list/menu-items-list.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LeftNavMenuItemComponent } from './shsp/components/menu-items/left-nav-menu-item/left-nav-menu-item.component';
import { KHElementsModule } from './kh-elements/kh-elements.module';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SHSPCoreModule } from './shsp-core/shsp-core.module';
import { Session$Pipe } from './_pipes/session$.pipe';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ToastrModule } from 'ngx-toastr';
import { NgArrayPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { LearnMoreDialogComponent } from './shsp/components/dialogs/learn-more-dialog/learn-more-dialog.component';
import { CloseButtonComponent } from './kh-elements/buttons/close-button/close-button.component';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AuthenticationService } from './authentication/authentication.service';
import { ErrorInterceptor } from './_helpers/error.interceptor';
// import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';

@NgModule({
    declarations: [
        AppComponent,

        // buttons
        CloseButtonComponent,

        // dialogs
        LearnMoreDialogComponent,

        // lists
        MenuItemsListComponent,

        // menu-items
        LeftNavMenuItemComponent,

        // pipes
        Session$Pipe,
    ],
    entryComponents: [LearnMoreDialogComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatDialogModule,

        NgArrayPipesModule,
        NgObjectPipesModule,
        // NgSlimScrollModule,

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),

        // angular material
        MatExpansionModule,
        MatFormFieldModule,

        // kh modules
        KHElementsModule.forRoot(),

        // shsp modules
        SHSPCoreModule.forRoot(),

        // others
        FontAwesomeModule,
        PDFExportModule,
        ToastrModule.forRoot(),
        ExcelExportModule, // ToastrModule added
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },

        DynamicScriptLoaderService,

        {
            provide: HTTP_INTERCEPTORS,
            useFactory: function(authenticationService: AuthenticationService){
                return new ErrorInterceptor(authenticationService);
            },
            multi: true,
            deps: [AuthenticationService]
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
