import { Injector, Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';

@Pipe({
    name: 'formatFields'
})
export class FormatFieldsPipe implements PipeTransform {
    public constructor(private injector: Injector) {}

    transform(value: any, template: string, fields: string[]): any {
        return of('asdfg');
    }
}
