import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TimeFrame } from '../enums/time-frame.enum';

/*
 * returns the length of an array or string
 */
@Pipe({ name: 'filterByTimeframe' })
export class FilterByTimeframePipe implements PipeTransform {
    transform(values: any[], fieldName: string, timeFrame: TimeFrame): any[] {
        const filtered = _.filter(values, value => {
            const date = value[fieldName];
            if (date) {
                switch (timeFrame) {
                    case TimeFrame.CurrentMonth: {
                        return moment(date).isSame(moment(), 'month');
                        break;
                    }
                    case TimeFrame.CurrentYear: {
                        return moment(date).isSame(moment(), 'year');
                        break;
                    }
                    default: {
                        debugger;
                        break;
                    }
                }
            } else {
                debugger;
            }
        });

        return filtered;
    }
}
