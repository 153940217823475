import { Injector, Pipe, PipeTransform } from '@angular/core';
import { ValueType } from '@app/shsp-core/_enums/value-type.enum';

@Pipe({
    name: 'dynamic',
})
// use a pipe based on it's token/name
export class DynamicPipe implements PipeTransform {
    public constructor(private injector: Injector) {}

    transform(
        value: any,
        pipeToken?: any,
        valueType?: ValueType,
        pipeArgs?: any[]
    ): any {
        if (!pipeToken) {
            return value;
        } else {
            // tslint:disable-next-line: deprecation
            const pipe = this.injector.get(pipeToken);

            if (pipeArgs) {
                return pipe.transform(value, ...pipeArgs);
            } else {
                return pipe.transform(value, valueType);
            }
        }
    }
}
