import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication/authentication.service';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IActionStep } from '../_models/action-step.model';
import { IActionStepStatus } from '../_models/action-step-status.model';
import { ApiService } from './api-service.service';
import { CacheService } from './cache.service';

@Injectable({
    providedIn: 'root',
})
export class ActionStepStatusService extends ApiService<IActionStepStatus> {
    constructor(
        authService: AuthenticationService,
        cacheService: CacheService,
        httpClient: HttpClient
    ) {
        super('ActionStepStatuses', authService, cacheService, httpClient);
    }

    loadForActionSteps(actionSteps: IActionStep[]) {
        debugger;
        if (actionSteps && actionSteps.length) {
            const actionStepStatusIDs = _.map(
                actionSteps,
                'ActionStepStatusID'
            );
            return this.loadForIDs$(actionStepStatusIDs).pipe(
                tap(actionStepStatuses => {
                    const actionStepStatusesKeyedByID = _.keyBy(
                        actionStepStatuses,
                        'ID'
                    );
                    _.forEach(actionSteps, actionStep => {
                        const actionStepStatus =
                            actionStepStatusesKeyedByID[
                                actionStep.ActionStepStatusID
                            ];
                        actionStep.ActionStepStatus = actionStepStatus;
                    });
                })
            );
        }

        return of<IActionStepStatus[]>([]);
    }
}
