import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, observable, pipe } from 'rxjs';

import { AuthenticationService } from '@app/authentication/authentication.service';
import * as _ from 'lodash';

import { shareReplay, map, tap, flatMap } from 'rxjs/operators';
import { IApiModel } from '@models/api.model';

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    public apiCacheMap = new Map<any, Observable<any[]>>();
    apiCacheMapSingle = new Map<any, Observable<any>>();

    private dataStore: {};

    constructor() {}

    invalidateAll() {
        this.invalidateApiCacheMapT();
    }

    invalidateApiCacheMapT() {
        this.apiCacheMap = new Map<any, Observable<any[]>>();
    }
}
