import { Pipe, PipeTransform } from '@angular/core';
import { AgencyService } from '../../../shsp/_services/agency.service';
import * as _ from 'lodash';

import { of } from 'rxjs';
import { MenuItemService } from '../../../shsp/_services/menu-item.service';
import { ValueType } from '@app/shsp-core/_enums/value-type.enum';

@Pipe({
    name: 'menuItem$',
})
export class MenuItem$Pipe implements PipeTransform {
    constructor(private menuItemService: MenuItemService) {}

    transform(value: any, valueType: ValueType): any {
        switch (valueType) {
            case ValueType.User: {
                debugger;
                return this.menuItemService.loadForID(value.ID);
            }
            default: {
                // assume value is a menuItemID
                debugger;
                return this.menuItemService.loadForID(value);
            }
        }
    }
}
