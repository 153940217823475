<div class="learn-more-dialog-container">
    <div
        class="container"
        fxLayout="column"
        fxLayoutAlign="space-evenly stretch"
    >
        <mat-dialog-content fxFlex>
            <div class="t-a-r">
                <app-close-button
                    (closeEventEmitter)="closeDialog()"
                ></app-close-button>
            </div>
            <div class="paragraph">
                <div class="paragraph-title">Status Keys</div>
                <div class="paragraph-content">
                    <div
                        *ngIf="
                            allActionStepStatuses$
                                | async as allActionStepStatuses
                        "
                        class="row clearfix jsdemo-notification-button"
                    >
                        <div
                            *ngFor="let actionStatus of allActionStepStatuses"
                            style="white-space: nowrap;"
                        >
                            <div
                                class="m-h-5"
                                fxLayout="row"
                                fxLayoutAlign="space-evenly center"
                            >
                                <i
                                    [ngStyle]="{ color: actionStatus.Color }"
                                    class="fas fa-circle"
                                ></i>
                                <span class="m-l-5">{{
                                    actionStatus.Name
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="paragraph">
                <div class="paragraph-title">General Information</div>
                <div class="paragraph-content">
                    <b>Webpage URL:</b> https://adot-dev.khsafetytools.com
                </div>

                <div class="paragraph-content">
                    The SHSP Tracking Tool enables safety stakeholders to track
                    implementation progress on each action and facilitates
                    evaluation of the SHSP. The tool is organized by Emphasis Area,
                    with the Safe System Elements included under each, with
                    the corresponding strategies and action steps. The system
                    allows users to add, edit, and update information related to
                    the status of the action steps. The tracking tool also
                    allows users to generate specific reports by criteria, such
                    as Emphasis Area and agency stakeholders to observe progress and
                    identify obstacles in real time. Furthermore, it hosts a
                    document library memorializing all activities under the
                    SHSP.
                </div>
            </div>

            <div class="paragraph">
                <div class="paragraph-title">Contact</div>
                <div class="paragraph-content">
                    <!-- For more information on Arizona's SHSP, the Emphasis Area task
                    forces or the Arizona Advisory Committee on Traffic Safety,
                    visit
                    <a
                        href="https://zerofatalitiesnv.com/safety-plan-what-is-the-shsp/"
                        target="_blank"
                        >https://zerofatalitiesnv.com/safety-plan-what-is-the-shsp/</a
                    >. 
                    To reach the State SHSP Administrator, contact Fred
                    Shakal (fshakal@dot.nv.gov). -->
                </div>
            </div>
        </mat-dialog-content>
    </div>
</div>
