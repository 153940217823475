import { Component, OnInit, ViewChild } from '@angular/core';
import {
    Event,
    Router,
    NavigationStart,
    NavigationEnd,
    RouterEvent,
    ActivatedRoute,
} from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { AuthenticationService } from './authentication/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuItemsListComponent } from './shsp/components/lists/menu-items-list/menu-items-list.component';
import { IMenuItem } from './shsp/_models/menu-item.model';
import { Observable, of } from 'rxjs';
import { ISession } from './authentication/_models/session';
import { ValueType } from './shsp-core/_enums/value-type.enum';
import { MenuItemService } from './shsp/_services/menu-item.service';
import { filter } from 'rxjs/operators';

declare const $: any;
declare var gtag;

// const { detect } = require('detect-browser');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild(MenuItemsListComponent) menuItemsListComponent: MenuItemsListComponent;
    ValueType = ValueType;

    learnMoreMenuItem$ = of<IMenuItem>();
    navigationEnd: NavigationEnd;

    title = 'my-app';
    currentUrl: string;
    showLoadingIndicator = true;
    currentSession$ = this.authService.currentSession$;
    version = '1.0.9';

    constructor(
        public authService: AuthenticationService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        protected router: Router,
        location: PlatformLocation,
        private menuItemService: MenuItemService
    ) {
        this.learnMoreMenuItem$ = this.menuItemService.loadForID('Learn More');

        // Setup google analytics
        const navEndEvent$ = router.events.pipe(
            filter(e => e instanceof NavigationEnd)
          );
          navEndEvent$.subscribe((e: NavigationEnd) => {
            const pageTitle = this.getTitle(router.routerState, router.routerState.root).join('-');
            // const inputData = this.route.snapshot.params['Id'];
            // debugger;
            console.log(pageTitle);
            // gtag('config', 'G-ME4EG7TQQK', {'page_path':e.urlAfterRedirects});
            // gtag('config', 'G-ME4EG7TQQK', {'page_title': pageTitle});
          });

        // handle the case where we don't detect the browser

        // remove the container template for auth related routes
        this.router.events.subscribe((routerEvent: Event) => {
            const hideContainer = this.router.url.includes(
                'authentication/reset-password'
            );

            if (routerEvent instanceof NavigationStart) {
                this.showLoadingIndicator = true;
                location.onPopState(() => {
                    window.location.reload();
                });

                this.currentUrl = routerEvent.url.substring(
                    routerEvent.url.lastIndexOf('/') + 1
                );
            }

            const trimmedCurrentURL = this.currentUrl.trim();

            if (
                hideContainer ||
                trimmedCurrentURL === '' ||
                trimmedCurrentURL === 'forgot-password' ||
                trimmedCurrentURL === 'login' ||
                trimmedCurrentURL === 'logout' ||
                trimmedCurrentURL === 'locked' ||
                trimmedCurrentURL === 'page404' ||
                trimmedCurrentURL === 'page500' ||
                trimmedCurrentURL.includes('signin') ||
                trimmedCurrentURL.includes('unsupported-browser') ||
                trimmedCurrentURL === 'signup'
            ) {
                document.getElementById('main-component').style.display =
                    'none';
            } else {
                document
                    .getElementById('main-component')
                    .style.removeProperty('display');
            }

            if (routerEvent instanceof NavigationEnd) {
                this.showLoadingIndicator = false;
                this.navigationEnd = routerEvent;
            }
            window.scrollTo(0, 0);
        });
    }

    ngOnInit() {
        //  this.activateNotificationAndTasksScroll();
    }

    showVersion() {
        alert(`version ${this.version}`);
    }


    getTitle(state, parent) {
        var data = [];
        if(parent && parent.snapshot.data && parent.snapshot.data.title) {
          data.push(parent.snapshot.data.title);
        }
    
        if(state && parent) {
          data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
      }

    // showUnsupportedBrowserMessage() {
    //     // this.debugger;
    //     // not logged in so redirect to login page with the return url
    //     console.log('navigating to unsupported browser page');
    //     debugger;
    //     this.router.navigate(
    //         ['shsp/unsupported-browser']
    //         // {
    //         //     queryParams: {
    //         //         returnUrl: state.url,
    //         //     },
    //         // }
    //     );
    // }
}
