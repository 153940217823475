import { Pipe, PipeTransform } from '@angular/core';
/*
 * returns the length of an array or string
 */
@Pipe({ name: 'length' })
export class LengthPipe implements PipeTransform {
    transform(value: any[] | string): number {
        return value.length;
    }
}
