import { Injector, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'transformEach'
})
// use a pipe based on it's token/name
export class TransformEachPipe implements PipeTransform {
    public constructor() {}

    transform(value: any, extendValue: any): any {
        if (value.length) {
            const transformedArr = _.map(value, item => {
                return { ...item, ...extendValue };
            });

            debugger;
            return transformedArr;
        }

        return value;
    }
}
