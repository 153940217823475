import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication/authentication.service';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {
    catchError,
    delay,
    flatMap,
    map,
    shareReplay,
    tap,
} from 'rxjs/operators';
import { ICriticalEmphasisAreaAndFileMapping } from '../_models/critical-emphasis-area-and-file-mapping.model';
import { ICriticalEmphasisAreaAndUserMapping } from '../_models/critical-emphasis-area-and-user-mapping.model';
import { ICriticalEmphasisArea } from '../_models/critical-emphasis-area.model';
import { IKeyArea } from '../_models/key-area.model';
import { IStrategy } from '../_models/strategy.model';
import { ApiService, ICallGetOptions } from './api-service.service';
import { CacheService } from './cache.service';
import { CriticalEmphasisAreaAndUserMappingService } from './critical-emphasis-area-and-user-mapping.service';
import { UserService } from './user.service';

export interface ILoadCriticalEmphasisAreaAndFileMappingsForCriticalEmphasisAreaIDOptions {
    callGetOptions: ICallGetOptions;
    timeout: number;
}

@Injectable({
    providedIn: 'root',
})
export class CriticalEmphasisAreaService extends ApiService<
    ICriticalEmphasisArea
> {
    apiCacheMap = new Map<any, Observable<ICriticalEmphasisArea[]>>();

    criticalEmphasisAreaVMs$: Observable<ICriticalEmphasisArea[]>;
    criticalEmphasisAreaVMs: BehaviorSubject<ICriticalEmphasisArea[]>;

    constructor(
        authService: AuthenticationService,
        cacheService: CacheService,
        httpClient: HttpClient,
        private criticalEmphasisAreaAndUserMappingService: CriticalEmphasisAreaAndUserMappingService,
        private userService: UserService
    ) {
        super('CriticalEmphasisAreas', authService, cacheService, httpClient);

        // this.dataStore = { criticalEmphasisAreaVMs: [] };

        this.criticalEmphasisAreaVMs = new BehaviorSubject(
            []
        ) as BehaviorSubject<ICriticalEmphasisArea[]>;
        this.criticalEmphasisAreaVMs$ = this.criticalEmphasisAreaVMs.asObservable();
    }

    createStrategyForCriticalEmphasisAreaID$(
        criticalEmphasisAreaID: string,
        strategy: IStrategy
    ) {
        const relativeURL = `${criticalEmphasisAreaID}/CreateStrategy`;
        const body = strategy;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$(relativeURL, body, callOptions);

        return response$.pipe(
            tap(res => {
                this.criticalEmphasisAreaAndUserMappingService.invalidateApiCacheMapT();
            })
        );
    }

    loadCriticalEmphasisAreaAndUserMappingsForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<ICriticalEmphasisAreaAndUserMapping[]>(
            `${criticalEmphasisAreaID}/CriticalEmphasisAreaAndUserMappings`,
            options && options.callGetOptions
        ).pipe(shareReplay(1));
    }

    removeFileForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        fileID: string
    ) {
        const relativeURL = `${criticalEmphasisAreaID}/Files/${fileID}/Remove`;
        return super.callPost$<number>(relativeURL);
    }

    loadAllCriticalEmphasisAreaModels() {
        debugger;
        // const currentSession = this.authService.currentSessionValue;
        const currentSession = null;
        if (currentSession) {
            const headers = {
                authorization: `khAuth ${currentSession.ID}`,
            };

            const getOptions = {
                headers,
            };

            const observable = this.httpClient
                .get<ICriticalEmphasisArea[]>(`${this.apiURL}`, getOptions)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        let data = {};
                        data = {
                            reason:
                                error && error.error.reason
                                    ? error.error.reason
                                    : '',
                            status: error.status,
                        };

                        return throwError(error);
                    })
                );

            return observable;
        }
    }

    loadCriticalEmphasisAreaAndFileMappingsForCriticalEmphasisAreaID$(
        criticalEmphasisAreaID: string,
        options?: ILoadCriticalEmphasisAreaAndFileMappingsForCriticalEmphasisAreaIDOptions
    ): Observable<ICriticalEmphasisAreaAndFileMapping[]> {
        const relativeURL = `${criticalEmphasisAreaID}/CriticalEmphasisAreaAndFileMappings`;
        const delayTimeout = options && options.timeout ? options.timeout : 0;
        return super
            .callGet$<ICriticalEmphasisAreaAndFileMapping[]>(
                relativeURL,
                options && options.callGetOptions
            )
            .pipe(delay(delayTimeout));
    }

    loadCriticalEmphasisAreasForCriticalEmphasisArea(
        criticalEmphasisArea: ICriticalEmphasisArea,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.loadCriticalEmphasisAreasForCriticalEmphasisAreaID(
            criticalEmphasisArea.ID
        );
    }

    loadCriticalEmphasisAreasForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<ICriticalEmphasisArea[]>(
            `${criticalEmphasisAreaID}/CriticalEmphasisAreas`,
            options && options.callGetOptions
        ).pipe(shareReplay(1));
    }

    loadCriticalEmphasisAreaModelForID(id: string) {
        // const currentSession = this.authService.currentSessionValue;
        const currentSession = null;
        if (currentSession) {
            const headers = {
                authorization: `khAuth {${currentSession.ID}}`,
            };

            const getOptions = {
                headers,
            };

            return this.httpClient.get<ICriticalEmphasisArea>(
                `${this.apiURL}/${id}`,
                getOptions
            );
        } else {
            // do nothing
        }
    }

    loadChairUserIDsForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<ICriticalEmphasisAreaAndUserMapping[]>(
            `${criticalEmphasisAreaID}/ChairUserIDs`,
            options && options.callGetOptions
        ).pipe(shareReplay(1));
    }

    loadChairUsersForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.loadChairUserIDsForCriticalEmphasisAreaID(
            criticalEmphasisAreaID
        ).pipe(
            flatMap(userIDs => {
                return this.userService.loadForIDs$(userIDs);
            })
        );
    }

    // loadForActionStepStatusIDsAndKeyAreaID$(
    //     actionStepStatusIDs: string[],
    //     keyAreaID: string
    // ) {
    //     return this.callPost$('FindMany', {
    //         KeyAreaIDs: keyAreaIDs,
    //     }) as Observable<ICriticalEmphasisArea[]>;
    // }

    loadForKeyAreas$(keyAreas: IKeyArea[]) {
        const keyAreaIDs = _.map(keyAreas, ka => ka.ID);
        return this.loadForKeyAreaIDs$(keyAreaIDs);
    }

    loadForKeyAreaIDs$(keyAreaIDs: string[]) {
        return this.callPost$('SearchMany', {
            KeyAreaIDs: keyAreaIDs,
        }) as Observable<ICriticalEmphasisArea[]>;
    }

    loadMemberUserIDsForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.callGet$<ICriticalEmphasisAreaAndUserMapping[]>(
            `${criticalEmphasisAreaID}/MemberUserIDs`,
            options && options.callGetOptions
        ).pipe(shareReplay(1));
    }

    loadMemberUsersForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        options?: { bypassLoaded: boolean; callGetOptions: ICallGetOptions }
    ) {
        return this.loadMemberUserIDsForCriticalEmphasisAreaID(
            criticalEmphasisAreaID
        ).pipe(
            flatMap(userIDs => {
                return this.userService.loadForIDs$(userIDs);
            })
        );
    }

    loadForActionStepStatusIDsAndCompletionYear(
        actionStepStatusIDs: string[],
        completionYear: string
    ) {
        let relativeURL = '';

        if (actionStepStatusIDs && actionStepStatusIDs.length) {
            relativeURL += `ActionStepStatus/${actionStepStatusIDs}/`;
        }

        if (completionYear) {
            relativeURL += `CompletionYear/${completionYear}`;
        }

        return super.callGet$<ICriticalEmphasisArea[]>(relativeURL);
    }

    loadForActionStepStatusIDAndCompletionYear(
        actionStepStatusID: string,
        completionYear: string
    ) {
        debugger;
        let relativeURL = '';

        if (actionStepStatusID) {
            relativeURL += `ActionStepStatus/${actionStepStatusID}/`;
        }

        if (completionYear) {
            relativeURL += `CompletionYear/${completionYear}`;
        }

        return super.callGet$<ICriticalEmphasisArea[]>(relativeURL);
    }

    loadForAgencyIDAndCriticalEmphasisAreaIDs(
        agencyID: string,
        criticalEmphasisAreaIDs: string[]
    ) {
        const relativeURL = 'SearchMany';
        const body = {
            AgencyID: agencyID,
            CriticalEmphasisAreaIDs: criticalEmphasisAreaIDs,
        };

        return super.callPost$<ICriticalEmphasisArea[]>(relativeURL, body);
    }

    loadForAgencyIDAndCompletionYear(agencyID: string, completionYear: string) {
        let relativeURL = '';

        if (agencyID) {
            relativeURL += `Agency/${agencyID}/`;
        }

        if (completionYear) {
            relativeURL += `CompletionYear/${completionYear}`;
        }

        return super.callGet$<ICriticalEmphasisArea[]>(relativeURL);
    }

    loadForBody$(body: {
        ActionStepIDs?: string[];
        ActionStepStatusIDs?: string[];
        AgencyID?: string;
        CompletionYear?: string;
        KeyAreaIDs?: string[];
        UserID?: string;
    }) {
        if (body) {
            const relativeURL = `SearchMany`;
            return this.callPost$<ICriticalEmphasisArea[]>(relativeURL, body);
        } else {
            return of<ICriticalEmphasisArea[]>([]);
        }
    }

    loadForCompletionYear(completionYear: string) {
        let relativeURL = '';

        debugger;
        if (completionYear) {
            relativeURL += `CompletionYear/${completionYear}`;
        }

        return this.callGet$<ICriticalEmphasisArea[]>(relativeURL);
    }

    loadStrategiesForCriticalEmphasisAreaID$(criticalEmphasisAreaID: string) {
        let relativeURL = `${criticalEmphasisAreaID}/Strategies`;
        return this.callGet$<IStrategy[]>(relativeURL).pipe(
            map(strategies => {
                return strategies as IStrategy[];
            })
        );
    }

    setChairUsersForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        chairUserIDs: string[]
    ) {
        const relativeURL = `${criticalEmphasisAreaID}/SetChairUsers`;
        const body = chairUserIDs;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$<
            ICriticalEmphasisAreaAndUserMapping
        >(relativeURL, body, callOptions);

        return response$.pipe(
            tap(res => {
                this.criticalEmphasisAreaAndUserMappingService.invalidateApiCacheMapT();
            })
        );
    }

    setMemberUsersForCriticalEmphasisAreaID(
        criticalEmphasisAreaID: string,
        chairUserIDs: string[]
    ) {
        const relativeURL = `${criticalEmphasisAreaID}/SetMemberUsers`;
        const body = chairUserIDs;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$<
            ICriticalEmphasisAreaAndUserMapping
        >(relativeURL, body, callOptions);

        return response$.pipe(
            tap(res => {
                this.criticalEmphasisAreaAndUserMappingService.invalidateApiCacheMapT();
            })
        );
    }

    toggleIsFavoriteForCriticalEmphasisAreaID(criticalEmphasisAreaID: string) {
        const relativeURL = `${criticalEmphasisAreaID}/ToggleIsFavorite`;
        const body = null;
        const callOptions = { bypassLoaded: true };
        const response$ = this.callPostSingle$<
            ICriticalEmphasisAreaAndUserMapping
        >(relativeURL, body, callOptions);

        return response$.pipe(
            tap(res => {
                this.criticalEmphasisAreaAndUserMappingService.invalidateApiCacheMapT();
            })
        );
    }
}
