import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { ApiService } from './api-service.service';
import { IUser } from '../_models/user.model';
import { CacheService } from './cache.service';

import * as _ from 'lodash';
import { map, mergeMap } from 'rxjs/operators';
import { UserAndUserGroupMappingService } from './user-and-user-group.service';

@Injectable({
    providedIn: 'root',
})
export class UserService extends ApiService<IUser> {
    constructor(
        protected authService: AuthenticationService,
        protected cacheService: CacheService,
        protected http: HttpClient,
        protected userAndUserGroupMappingService: UserAndUserGroupMappingService
    ) {
        super('Users', authService, cacheService, http);
    }

    loadAuthenticatedUsers() {
        return this.userAndUserGroupMappingService.loadAll$().pipe(
            mergeMap(mappings => {
                const userIDs = _.uniq(mappings.map(m => m.UserID));
                return this.loadForIDs$(userIDs);
            })
        );
    }

    loadActionStepsForUserID$(userID: string) {
        return this.callGet$(`${userID}/ActionSteps`);
    }
}
