import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'aOrAn',
})
export class AOrAnPipe implements PipeTransform {
    constructor() {}

    transform(value: string): string {
        const aOrAn = this.isVowel(value[0]) ? 'an' : 'a';
        return [aOrAn, value].join(' ');
    }

    isVowel(char: string) {
        // return ['a', 'e', 'i', 'o', 'u'].indexOf(char.toLowerCase()) !== -1;
        return ['a', 'e', 'i', 'o'].indexOf(char.toLowerCase()) !== -1;
    }
}
