import { Pipe, PipeTransform } from '@angular/core';
import { ValueType } from '@app/shsp-core/_enums/value-type.enum';
import { IUser } from '@app/shsp/_models/user.model';
import { ActionStepAndUserMappingService } from '@app/shsp/_services/action-step-and-user-mapping.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AgencyService } from '../../../shsp/_services/agency.service';
import { UserService } from '../../../shsp/_services/user.service';

@Pipe({
    name: 'users$',
})
export class Users$Pipe implements PipeTransform {
    constructor(
        private agencyService: AgencyService,
        private actionStepAndUserMappingService: ActionStepAndUserMappingService,
        private userService: UserService
    ) {}

    transform(
        value: any,
        valueType: ValueType,
        ...args: any[]
    ): Observable<IUser[]> {
        debugger;
        switch (valueType) {
            case ValueType.ActionStepAndUserMappings: {
                const userIDs = _.map(value, 'UserID');
                return this.userService.loadForIDs$(userIDs);
            }
            case ValueType.ActionStep: {
                return this.actionStepAndUserMappingService
                    .loadActionStepAndUserMappingsForActionStepID$(value.ID)
                    .pipe(
                        flatMap(mappings => {
                            const userIDs = _.map(mappings, 'UserID');
                            return this.userService.loadForIDs$(userIDs);
                        })
                    );
            }
            case ValueType.ActionStepID: {
                return this.actionStepAndUserMappingService
                    .loadActionStepAndUserMappingsForActionStepID$(value)
                    .pipe(
                        flatMap(mappings => {
                            debugger;
                            const userIDs = _.map(mappings, 'UserID');
                            return this.userService.loadForIDs$(userIDs);
                        })
                    );
            }
            case ValueType.Agency: {
                return this.agencyService.loadUsersForAgencyID(value.ID);
            }
            default: {
                break;
            }
        }
    }
}
