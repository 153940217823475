import { Pipe, PipeTransform } from '@angular/core';
import { AgencyService } from '../../../shsp/_services/agency.service';
import * as _ from 'lodash';

import { of, Observable } from 'rxjs';
import { MenuItemService } from '../../../shsp/_services/menu-item.service';
import { ValueType } from '@app/shsp-core/_enums/value-type.enum';
import { IMenuItem } from '@app/shsp/_models/menu-item.model';

@Pipe({
    name: 'menuItems$',
})
export class MenuItems$Pipe implements PipeTransform {
    constructor(private menuItemService: MenuItemService) {}

    transform(
        value: any,
        valueType: ValueType,
        forLeftSide: boolean
    ): Observable<IMenuItem[]> {
        switch (valueType) {
            case ValueType.Session: {
                if (forLeftSide) {
                    return this.menuItemService.loadAllForLeftSidenav();
                } else {
                    return this.menuItemService.loadAll$();
                }
            }
            case ValueType.User: {
                // return this.menuItemService.loadForID(value.ID);
                debugger;
                break;
            }
            default: {
                // assume value is menuItemIDs
                debugger;
                return this.menuItemService.loadForIDs$(value);
            }
        }
    }
}
