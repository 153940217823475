import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LengthPipe } from './pipes/length.pipe';
import { IsEmptyPipe } from './pipes/is-empty.pipe';
import { HasKeyPipe } from './pipes/has-key.pipe';
import { IsNullOrEmptyPipe } from './pipes/is-null-or-empty.pipe';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { FormatFieldsPipe } from './pipes/format-values.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TransformEachPipe } from './pipes/transform-each.pipe';

import { MenuItem$Pipe } from './pipes/auth/menu-item.pipe';
import { MenuItems$Pipe } from './pipes/auth/menu-items.pipe';
import { FilterByTimeframePipe } from './pipes/filter-by-timeframe.pipe';
import { AOrAnPipe } from './pipes/a-or-an.pipe';

import { User$Pipe } from './pipes/auth/user$.pipe';
// import { User2$Pipe } from '@app/shsp/_pipes/user2$.pipe';
import { Users$Pipe } from './pipes/auth/users.pipe';
import { DefaultPipe } from './pipes/default.pipe';

@NgModule({
    declarations: [
        // buttons

        // pipes
        AOrAnPipe,
        DefaultPipe,
        DynamicPipe,
        FilterByTimeframePipe,
        FormatFieldsPipe,
        HasKeyPipe,
        IsEmptyPipe,
        IsNullOrEmptyPipe,
        LengthPipe,
        TransformEachPipe,

        // pipes - async
        MenuItem$Pipe,
        MenuItems$Pipe,
        User$Pipe,
        // User2$Pipe,
        Users$Pipe,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatButtonToggleModule,
        MatIconModule,
        MatMenuModule,
    ],
    exports: [
        // custom pipes
        AOrAnPipe,
        DefaultPipe,
        DynamicPipe,
        FilterByTimeframePipe,
        FormatFieldsPipe,
        HasKeyPipe,
        IsEmptyPipe,
        IsNullOrEmptyPipe,
        LengthPipe,
        TransformEachPipe,

        // custom pipes - async
        MenuItem$Pipe,
        MenuItems$Pipe,
        User$Pipe,
        // User2$Pipe,
        Users$Pipe,

        // angular material
        MatButtonToggleModule,
        MatIconModule,
        MatMenuModule,
    ],
})
export class KHElementsModule {
    static forRoot(): ModuleWithProviders<KHElementsModule> {
        return {
            ngModule: KHElementsModule,
            providers: [],
        };
    }
}
