import * as _ from 'lodash';
import {
    Component,
    OnInit,
    Input,
    ViewChildren,
    QueryList,
    AfterViewInit,
    ViewChild,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    Output,
} from '@angular/core';
import { IMenuItem } from '@app/shsp/_models/menu-item.model';
import { Router } from '@angular/router';
import { SlugifyPipe } from 'ngx-pipes';

declare var $: any;

@Component({
    providers: [SlugifyPipe],
    selector: 'app-left-nav-menu-item',
    templateUrl: './left-nav-menu-item.component.html',
    styleUrls: ['./left-nav-menu-item.component.scss'],
})
export class LeftNavMenuItemComponent implements OnInit {
    // tslint:disable-next-line: variable-name
    private _selectedMenuItems: IMenuItem[];

    hasOnClick = true;

    @ViewChild('element') element;
    @ViewChild('parentMenuItemAnchor') parentMenuItemAnchor;

    @Input() childMenuItems: IMenuItem[];
    @Input() menuItem: IMenuItem;
    @Input() parentMenuItem: IMenuItem;

    @Input()
    set selectedMenuItems(selectedMenuItems: IMenuItem[]) {
        this._selectedMenuItems = selectedMenuItems;

        if (selectedMenuItems && selectedMenuItems.length) {
            if (
                selectedMenuItems.some(
                    selectedMenuItem => selectedMenuItem.ID === this.menuItem.ID
                )
            ) {
                if (this.childMenuItems && this.childMenuItems.length) {
                    this.expandMenuItem();
                }
            }
        }
    }

    get selectedMenuItems(): IMenuItem[] {
        return this._selectedMenuItems;
    }

    @Output() overrideClick = new EventEmitter();

    constructor(private router: Router, private slugifyPipe: SlugifyPipe) {}

    ngOnInit() {}

    expandMenuItem() {
        if (this.parentMenuItemAnchor) {
            const $a = $(this.parentMenuItemAnchor.nativeElement);
            if ($a.hasClass('toggled')) {
                return;
            }

            const $content = $a.next();
            const parentULs = $a.parents('ul');

            $a.addClass('toggled');
            $content.slideToggle(320);
        }
    }

    onClick(): void {
        if (this.overrideClick && this.overrideClick.observers.length) {
            this.overrideClick.emit();
        } else {
            let url = 'shsp/';

            if (this.parentMenuItem) {
                url += `${this.slugifyPipe.transform(
                    this.parentMenuItem.Name
                )}/${this.slugifyPipe.transform(this.menuItem.Name)}`;
            } else {
                url += `${this.slugifyPipe.transform(this.menuItem.Name)}`;
            }

            this.router.navigateByUrl(url, {});
        }
    }

    toggleCollapseMenuItem($event) {
        const $a = $(this.parentMenuItemAnchor.nativeElement);
        const $content = $a.next();

        const parentULs = $a.parents('ul');
        $a.toggleClass('toggled');
        $content.slideToggle(320);
    }
}
