import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'hasKey'
})
export class HasKeyPipe implements PipeTransform {
    constructor() {}

    transform(value: any, key: string): any {
        return value.hasOwnProperty(key);
    }
}
