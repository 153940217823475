import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { IStrategyAndUserMapping } from '@models/strategy-and-user-mapping.model';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { IStrategy } from '../_models/strategy.model';
import { IUser } from '../_models/user.model';
import { ApiService, ICallPostOptions } from './api-service.service';
import { CacheService } from './cache.service';

export interface LoadAllVMsOptions {
    includeCoLeadUserVMs: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class StrategyAndUserMappingService extends ApiService<
    IStrategyAndUserMapping
> {
    usersObseravle: Observable<IUser[]>;

    private usersBS: BehaviorSubject<IUser[]>;

    constructor(
        authService: AuthenticationService,
        cacheService: CacheService,
        httpClient: HttpClient
    ) {
        super('StrategyAndUserMappings', authService, cacheService, httpClient);

        this.usersBS = new BehaviorSubject([]) as BehaviorSubject<IUser[]>;
        this.usersObseravle = this.usersBS.asObservable();
    }

    loadStrategyAndUserMappingModelsForStrategyID(keyAreaID: string) {
        const currentSession = null;
        // const currentSession = this.authService.currentSessionValue;
        if (currentSession) {
            const headers = {
                authorization: `khAuth {${currentSession.ID}}`,
            };

            const postOptions = {
                headers,
            };

            const observable = this.httpClient.post<IStrategyAndUserMapping[]>(
                `${this.apiURL}/FindManyForStrategyIDs`,
                [keyAreaID],
                postOptions
            );

            return observable;
        } else {
            // do nothing
        }
    }

    loadForStrategyIDs(
        keyAreaIDs: string[],
        options?: { bypassLoaded: boolean; callPostOptions: ICallPostOptions }
    ) {
        return this.callPost$(
            `FindManyForStrategyIDs`,
            keyAreaIDs,
            options && options.callPostOptions
        );
    }

    loadForStrategies(
        keyAreas: IStrategy[],
        options?: {
            loadForStrategyIDs: {
                bypassLoaded: boolean;
                callPostOptions: ICallPostOptions;
            };
        }
    ) {
        const keyAreaIDs = _.map(keyAreas, ca => ca.ID);
        return this.loadForStrategyIDs(
            keyAreaIDs,
            options && options.loadForStrategyIDs
        );
    }

    loadCoLeadStrategyAndUserMappingModelsForStrategyID(keyAreaID: string) {
        return this.callPost$(`FindManyCoLeadsForStrategyIDs`, [keyAreaID]);
    }

    loadCoLeadStrategyAndUserMappingModelsForUserID(
        userID: string
    ): Observable<IStrategyAndUserMapping[]> {
        const relativeURL = `FindManyCoLeadsForUserID/${userID}`;
        return this.callGet$(relativeURL);
    }
}
