import { Pipe, PipeTransform } from '@angular/core';
import { ValueType } from '@app/shsp-core/_enums/value-type.enum';
import { SessionService } from '@app/authentication/_services/session.service';
import { ISession } from '@app/authentication/_models/session';
import { Observable } from 'rxjs';

import * as _ from 'lodash';

@Pipe({
    name: 'session$',
})
export class Session$Pipe implements PipeTransform {
    constructor(private sessionService: SessionService) {}

    transform(value: any, valueType?: ValueType): Observable<ISession> {
        switch (valueType) {
            default: {
                // assume value is a sessionID
                return this.sessionService.loadForID(value);
            }
        }
    }
}
