import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IActionStepStatus } from '@app/shsp/_models/action-status.model';
import { ActionStepStatusService } from '@app/shsp/_services/action-step-status.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-learn-more-dialog',
    templateUrl: './learn-more-dialog.component.html',
    styleUrls: ['./learn-more-dialog.component.scss'],
})
export class LearnMoreDialogComponent implements OnInit {
    allActionStepStatuses$: Observable<IActionStepStatus[]>;

    canBeClosed = true;
    primaryButtonText = 'OK';
    showCancelButton = true;

    constructor(
        private actionStepStatusService: ActionStepStatusService,
        public dialogRef: MatDialogRef<LearnMoreDialogComponent>
    ) {}

    ngOnInit() {
        debugger;
        this.allActionStepStatuses$ = this.actionStepStatusService.loadAll$();
    }

    closeDialog() {
        return this.dialogRef.close();

        // The OK-button should have the text "Finish" now
        this.primaryButtonText = 'Finish';

        // The dialog should not be closed while uploading
        this.canBeClosed = false;
        this.dialogRef.disableClose = true;

        // Hide the cancel-button
        this.showCancelButton = false;

        // ... the dialog can be closed again...
        this.canBeClosed = true;
        this.dialogRef.disableClose = false;

        // this.dialogRef.close(this.files);
    }
}
