<div class="menu-items-list-container">
    <div
        *ngIf="
            menuItems
                | groupBy
                    : 'ParentMenuItemID' as menuItemsGroupedByParentMenuItemID
        "
    >
        <ul class="menu">
            <li
                #renderedMenuItems
                *ngFor="
                    let menuItem of menuItemsGroupedByParentMenuItemID
                        | pick: 'null'
                        | values
                        | flatten
                        | truthify;
                    let last = last
                "
            >
                <hr *ngIf="menuItem.HasDivider" />

                <app-left-nav-menu-item
                    *ngIf="menuItem.Name == 'Learn More'"
                    (overrideClick)="openLearnMoreDialog()"
                    [childMenuItems]="
                        menuItemsGroupedByParentMenuItemID | pluck: menuItem.ID
                    "
                    [menuItem]="menuItem"
                    [selectedMenuItems]="selectedMenuItems"
                ></app-left-nav-menu-item>

                <app-left-nav-menu-item
                    *ngIf="menuItem.Name != 'Learn More'"
                    [childMenuItems]="
                        menuItemsGroupedByParentMenuItemID | pluck: menuItem.ID
                    "
                    [menuItem]="menuItem"
                    [selectedMenuItems]="selectedMenuItems"
                ></app-left-nav-menu-item>
            </li>
        </ul>
    </div>
</div>
