import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../../../shsp/_services/user.service';
import { ValueType } from '@app/shsp-core/_enums/value-type.enum';
import { IUser } from '@app/shsp/_models/user.model';
import { Observable } from 'rxjs';

@Pipe({
    name: 'user$',
})
export class User$Pipe implements PipeTransform {
    constructor(private userService: UserService) {}

    transform(value: any, valueType?: ValueType): Observable<IUser> {
        switch (valueType) {
            case ValueType.Session: {
                return this.userService.loadForID(value.UserID);
            }
            case ValueType.User: {
                return this.userService.loadForID(value.ID);
            }
            case ValueType.UserID: {
                return this.userService.loadForID(value);
            }
            default: {
                return this.userService.loadForID(value);
            }
        }
    }
}
