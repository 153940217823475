import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication/authentication.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IActionStepAndUserMapping } from '../_models/action-step-and-user-mapping.model';
import { ActionStepService } from './action-step.service';
import { ApiService } from './api-service.service';
import { CacheService } from './cache.service';

export interface LoadAllVMsOptions {
    includeCoLeadUserVMs: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ActionStepAndUserMappingService extends ApiService<
    IActionStepAndUserMapping
> {
    apiCacheMap = new Map<any, Observable<IActionStepAndUserMapping[]>>();

    constructor(
        private actionStepService: ActionStepService,
        protected authService: AuthenticationService,
        protected cacheService: CacheService,
        protected httpClient: HttpClient
    ) {
        super(
            'ActionStepAndUserMappings',
            authService,
            cacheService,
            httpClient
        );
    }

    loadActionStepAndUserMappingsForActionStepID$(actionStepID: string) {
        return this.actionStepService.callGet2$<IActionStepAndUserMapping[]>(
            `${actionStepID}/ActionStepAndUserMappings`
        );
    }

    loadActionStepAndUserMappingsForActionStepIDs$(actionStepIDs: string[]) {
        return this.callPost2$<IActionStepAndUserMapping[]>('FindMany', {
            ActionStepIDs: actionStepIDs,
        }).pipe(
            map(mappings => {
                return _.chain(mappings)
                    .groupBy(m => m.ActionStepID)
                    .value();
            })
        );
    }

    loadActionStepAndUserMappingsForUserID$(userID: string) {
        const cacheKey = userID;
        let mappings$ = this.apiCacheMap.get(cacheKey);
        if (mappings$) {
            return mappings$;
        } else {
            mappings$ = this.callPost$(`FindManyForUserIDs`, [userID]);

            this.apiCacheMap.set(cacheKey, mappings$);

            return mappings$;
        }
    }
}
