import { Injectable, Inject, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, observable, pipe } from 'rxjs';

import { AuthenticationService } from '@app/authentication/authentication.service';
import * as _ from 'lodash';

import { shareReplay, map, tap, flatMap, defaultIfEmpty } from 'rxjs/operators';
import { IApiModel } from '@models/api.model';
import { CacheService } from './cache.service';
import { environment } from '../../../environments/environment';
import { inject } from '@angular/core/testing';

export interface ICallGetOptions {
    bypassLoaded: boolean;
}

export interface ICallPostOptions {
    bypassLoaded: boolean;
}

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
    providedIn: 'root',
    factory: () => localStorage,
});

// @Injectable({
//     providedIn: 'root',
// })
export class ApiService<TModel extends IApiModel> {
    apiCacheMap = new Map<any, Observable<TModel[]>>();
    apiCacheMapSingle = new Map<any, Observable<TModel>>();
    apiCacheMapSingleT = new Map<any, Observable<any>>();

    // headers$ = of<{ authorization: string }>();

    private baseUrl = environment.api.baseURL;

    protected apiURL;

    constructor(
        controllerName: string,
        protected authService: AuthenticationService,
        protected cacheService: CacheService,
        protected httpClient: HttpClient
    ) {
        this.apiURL = _.join([this.baseUrl, controllerName], '/');
    }

    public addOrUpdateDataItem$(dataItem: TModel) {
        const relativeURL = 'AddOrUpdate';
        return this.callPostSingle$(relativeURL, dataItem);
    }

    // this is a legacy function that is currently used, but may eventaully be replaced accross the board with callGet2$
    // whenever a new functionlity is used for loading data from API, callGet2$ function should be used instead
    callGet$<T>(relativeURL?: string, options?: ICallGetOptions) {
        const url = relativeURL ? `${this.apiURL}/${relativeURL}` : this.apiURL;

        const cacheKey = url;

        let response$ = this.cacheService.apiCacheMap.get(cacheKey);
        if (response$ && (!options || !options.bypassLoaded)) {
            return response$;
        } else {
            response$ = this.httpClient
                .get<T[]>(url, { headers: this.authService.headers })
                .pipe(shareReplay(1));

            this.cacheService.apiCacheMap.set(cacheKey, response$);

            return response$;
        }
    }

    // this function is a newer version of callGet$ which tries to simplify the loading/caching process
    // whenever new functionly is used to get data from the API, this is the fuinction that should be used
    callGet2$<T>(relativeURL?: string) {
        const url = relativeURL ? `${this.apiURL}/${relativeURL}` : this.apiURL;
        // return this.httpClient
        //     .get<T>(url, { headers: this.authService.headers })
        //     .pipe(shareReplay(1));
        return this.httpClient.get<T>(url, {
            headers: this.authService.headers,
        });
    }

    callGetSingle<T>(relativeURL?: string, options?: ICallGetOptions) {
        const url = relativeURL ? `${this.apiURL}/${relativeURL}` : this.apiURL;

        const cacheKey = url;
        let response$ = this.apiCacheMapSingleT.get(cacheKey);
        if (response$ && (!options || !options.bypassLoaded)) {
            return response$;
        } else {
            response$ = this.httpClient
                .get<T>(url, { headers: this.authService.headers })
                .pipe(shareReplay(1));

            this.apiCacheMapSingle.set(cacheKey, response$);

            return response$;
        }
    }

    callPost$<T>(
        relativeURL: string,
        body: any = null,
        options?: ICallPostOptions
    ) {
        const url = relativeURL ? `${this.apiURL}/${relativeURL}` : this.apiURL;

        const cacheKey = JSON.stringify({ url, body });
        let response$ = this.cacheService.apiCacheMap.get(cacheKey);
        if (response$ && (!options || !options.bypassLoaded)) {
            return response$;
        } else {
            response$ = this.httpClient
                .post<T[]>(url, body, {
                    headers: this.authService.headers,
                })
                .pipe(shareReplay(1));

            this.cacheService.apiCacheMap.set(cacheKey, response$);

            return response$;
        }
    }

    /// update call post withi shareReply instead of local caching
    callPost2$<T>(
        relativeURL: string,
        body: any = null,
        options?: ICallPostOptions
    ) {
        const url = relativeURL ? `${this.apiURL}/${relativeURL}` : this.apiURL;
        return this.httpClient
            .post<T>(url, body, {
                headers: this.authService.headers,
            })
            .pipe(shareReplay(1));
    }

    callPostSingle$<T>(
        relativeURL: string,
        body: any = null,
        options?: ICallPostOptions
    ) {
        const url = relativeURL ? `${this.apiURL}/${relativeURL}` : this.apiURL;

        const cacheKey = JSON.stringify({ url, body });
        let response$ = this.apiCacheMapSingleT.get(cacheKey);
        if (response$ && (!options || !options.bypassLoaded)) {
            return response$;
        } else {
            response$ = this.httpClient
                .post<T>(url, body, {
                    headers: this.authService.headers,
                })
                .pipe(shareReplay(1));

            this.apiCacheMapSingleT.set(cacheKey, response$);

            return response$;
        }
    }

    public deletedForID(id: string) {
        const relativeURL = `${id}/Delete`;
        return this.callPost$(relativeURL);
    }

    getApiURL() {
        return this.apiURL;
    }

    getHeaders$(): Observable<{ authorization: string }> {
        // set options
        return this.authService.currentSession$.pipe(
            map(currentSession => {
                if (currentSession) {
                    const apiHeaders = {
                        authorization: `khAuth {${currentSession.ID}}`,
                    };

                    return apiHeaders;
                }

                return null;
            })
        );
    }

    invalidateApiCacheMapT(dataItems?: TModel[]) {
        this.cacheService.apiCacheMap = new Map<any, Observable<any[]>>();
    }

    public loadActive$(options?: {
        callGetOptions?: ICallGetOptions;
    }): Observable<TModel[]> {
        return this.callGet$<TModel[]>(
            '',
            options && options.callGetOptions
        ).pipe(
            map(models => {
                if (models) {
                    return _.chain(models)
                        .filter('IsActive')
                        .orderBy('SortOrder')
                        .value();
                } else {
                    return [];
                }
            })
        );
    }

    public loadAll$(options?: {
        callGetOptions?: ICallGetOptions;
    }): Observable<TModel[]> {
        return this.callGet$<TModel[]>(
            '',
            options && options.callGetOptions
        ).pipe(
            map(models => {
                if (models) {
                    return _.orderBy(models, 'SortOrder');
                } else {
                    return [];
                }
            })
        );
    }

    // simpler load all
    public loadAll2$(options?: {
        callGetOptions?: ICallGetOptions;
    }): Observable<TModel[]> {
        return this.callGet2$<TModel[]>('').pipe(
            map(models => {
                if (models) {
                    return _.orderBy(models, 'SortOrder');
                } else {
                    return [];
                }
            })
        );
    }

    loadForID(
        id: string,
        options?: { bypassLoaded: boolean }
    ): Observable<TModel> {
        if (id) {
            const cacheKey = id;
            let model$ = this.apiCacheMapSingleT.get(cacheKey);
            if (model$ && (!options || !options.bypassLoaded)) {
                return model$;
            } else {
                model$ = this.callGetSingle<TModel>(id).pipe(shareReplay(1));

                this.apiCacheMapSingleT.set(cacheKey, model$);

                return model$;
            }
        }

        return of<TModel>();
    }

    loadForIDs$(
        ids: string[],
        options?: { keepOrder: boolean }
    ): Observable<TModel[]> {
        if (ids && ids.length) {
            const uniqIDs = _.chain(ids)
                .compact()
                .uniq()
                .value();

            if (uniqIDs.length) {
                const cacheKey = _.join(uniqIDs, ',');
                let models$ = this.apiCacheMap.get(cacheKey);
                if (models$) {
                    if (options && options.keepOrder) {
                        return models$.pipe(
                            map(models => {
                                return _.orderBy(models, model =>
                                    _.indexOf(ids, model['ID'])
                                );
                            })
                        );
                    } else {
                        return models$;
                    }
                } else {
                    models$ = this.callPost$<TModel[]>(
                        'FindManyIds',
                        uniqIDs
                    ).pipe(
                        map(arr => {
                            if (arr) {
                                if (options && options.keepOrder) {
                                    return _.orderBy(arr, dataItem =>
                                        _.indexOf(ids, dataItem.ID)
                                    );
                                } else {
                                    return arr;
                                }
                            } else {
                                return [];
                            }
                        }),
                        shareReplay(1)
                    );

                    this.apiCacheMap.set(cacheKey, models$);

                    return models$;
                }
            } else {
                return of<TModel[]>([]);
            }
        } else {
            return of<TModel[]>([]);
        }
    }

    loadForName(name: string): Observable<TModel> {
        if (name) {
            const cacheKey = name;
            let model$ = this.apiCacheMapSingleT.get(cacheKey);
            if (model$) {
                return model$;
            } else {
                model$ = this.callGet$<TModel>(name).pipe(shareReplay(1));

                this.apiCacheMap.set(cacheKey, model$);

                return model$;
            }
        } else {
            return of<TModel>();
        }
    }

    loadForNames(names: string[]): Observable<TModel[]> {
        if (names && names.length) {
            const uniqNames = _.chain(names)
                .compact()
                .uniq()
                .value();

            if (uniqNames.length) {
                const cacheKey = _.join(uniqNames, ',');
                let models$ = this.apiCacheMap.get(cacheKey);
                if (models$) {
                    return models$;
                } else {
                    models$ = this.callPost$<TModel[]>(
                        'FindManyNames',
                        uniqNames
                    ).pipe(shareReplay(1));

                    this.apiCacheMap.set(cacheKey, models$);

                    return models$;
                }
            } else {
                return of<TModel[]>([]);
            }
        } else {
            return of<TModel[]>([]);
        }
    }

    update(dataItem: any): Observable<TModel> {
        let relativeURL = 'Update';
        if ('ID' in dataItem) {
            relativeURL = `${dataItem.ID}/Update`;
        }

        return this.callPostSingle$<TModel>(relativeURL, dataItem);
    }
}
