import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from '@app/authentication/authentication.service';
import * as _ from 'lodash';

import { ApiService } from './api-service.service';
import { IActionStepHistory } from '../_models/action-step-history.model';
import { Observable, of } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({
    providedIn: 'root',
})
export class ActionStepHistoryService extends ApiService<IActionStepHistory> {
    constructor(
        protected authService: AuthenticationService,
        protected cacheService: CacheService,
        protected http: HttpClient
    ) {
        super('ActionStepHistories', authService, cacheService, http);
    }

    findMany$(body: { actionStepIDs: string[] }) {
        const relativeURL = `FindMany`;
        return this.callPost2$<IActionStepHistory[]>(relativeURL, body);
    }

    loadForLastStatusChangeMonth(
        month: number
    ): Observable<IActionStepHistory[]> {
        const relativeURL = `GetActionStepHistoriesForLastStatusChangeMonth/${month}`;
        return this.callGet$(relativeURL);
    }

    loadForLastStatusChangeYear(
        year: number
    ): Observable<IActionStepHistory[]> {
        const relativeURL = `GetActionStepHistoriesForLastStatusChangeYear/${year}`;
        return this.callGet$(relativeURL);
    }
}
