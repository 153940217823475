import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { AuthenticationService } from '@app/authentication/authentication.service';
import * as _ from 'lodash';

import { ApiService } from './api-service.service';
import { IAgency } from '../_models/agency.model';
import { IUser } from '../_models/user.model';
import { CacheService } from './cache.service';

@Injectable({
    providedIn: 'root',
})
export class AgencyService extends ApiService<IAgency> {
    constructor(
        protected authService: AuthenticationService,
        cacheService: CacheService,
        protected http: HttpClient
    ) {
        super('Agencies', authService, cacheService, http);
    }

    loadUsersForAgencyID(agencyID: string): Observable<IUser[]> {
        const relativeURL = `${agencyID}/Users`;
        return this.callGet$(relativeURL);
    }
}
