import * as _ from 'lodash';

import {
    Component,
    OnInit,
    Input,
    ViewChildren,
    QueryList,
    AfterViewInit,
} from '@angular/core';
import { SlugifyPipe } from 'ngx-pipes';
import { IMenuItem } from '@app/shsp/_models/menu-item.model';
import { NavigationEnd } from '@angular/router';
import { MenuItemService } from '@app/shsp/_services/menu-item.service';
import { Observable, of } from 'rxjs';
import { LearnMoreDialogComponent } from '../../dialogs/learn-more-dialog/learn-more-dialog.component';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;

@Component({
    providers: [SlugifyPipe],
    selector: 'app-menu-items-list',
    templateUrl: './menu-items-list.component.html',
    styleUrls: ['./menu-items-list.component.scss'],
})
export class MenuItemsListComponent implements OnInit, AfterViewInit {
    @ViewChildren('renderedMenuItems') renderedMenuItems: QueryList<any>;

    @Input() menuItems: IMenuItem[];

    @Input()
    set navigationEnd(navigationEnd: NavigationEnd) {
        if (navigationEnd) {
            this.selectMenuItemForURL(navigationEnd.url);
        }
    }

    selectedMenuItems: IMenuItem[];

    constructor(
        private dialog: MatDialog,
        private menuItemService: MenuItemService,
        private slugifyPipe: SlugifyPipe
    ) {}

    // run this after init of page
    ngAfterViewInit() {
        // run this once all items in for loop are loaded
        this.renderedMenuItems.changes.subscribe(t => {
            $.each($('.menu-toggle.toggled'), (i, val) => {
                $(val)
                    .next()
                    .slideToggle(0);
            });
        });
    }

    ngOnInit() {}

    openLearnMoreDialog(): void {
        const dialogRef = this.dialog.open(LearnMoreDialogComponent, {
            width: '50%',
            height: '600px',
        });

        dialogRef.afterClosed().subscribe(files => {
            // this.uploadedFilesSet.emit(files);
        });
    }

    selectMenuItemForURL(url: string) {
        if (this.menuItems && this.menuItems.length) {
            this.selectedMenuItems = _.filter(this.menuItems, menuItem => {
                const dashedName = this.slugifyPipe.transform(menuItem.Name);
                return url.includes(dashedName);
            });
        }
    }
}
