<div *ngIf="showLoadingIndicator" class="page-loader-wrapper">
    <div class="loader">
        <div class="m-t-30">
            <div class="loading-img-spin" style="width: 20px; height:20px;">
                <i class="fas fa-spinner"></i>
            </div>
        </div>
        <p>Please wait...</p>
    </div>
</div>
<div class="overlay"></div>
<!-- #END# Overlay For Sidebars -->
<!-- Search Bar -->
<div id="main-component">
    <nav class="navbar">
        <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbar-collapse">
                <ul class="nav navbar-nav navbar-left m-0">
                    <li id="mainApplicationName" (dblclick)="showVersion()" class="pull-left">
                        <h1>Arizona SHSP Action Step Tracking Tool</h1>
                    </li>
                </ul>
                <ul class="nav navbar-nav navbar-right">
                    <li class="pull-right">
                        <!-- <img
                            src="../assets/images/icons/SHSP_logo_header-02_right_sm.png"
                        /> -->
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div>
        <!-- Left Sidebar -->

        <aside id="leftsidebar" class="sidebar">
            <!-- Menu -->
            <div *ngIf="authService.sessionID | session$ | async as session">
                <div
                    *ngIf="session | user$: ValueType.Session | async as user"
                    class="header profile-info"
                >
                    <h3>{{ user.FirstName }} {{ user.LastName }}</h3>
                    <p>{{ user.Email }}</p>
                </div>
                <div class="menu" style="max-height: auto;">
                    <ul class="list">
                        <li
                            *ngIf="
                                session
                                    | menuItems$: ValueType.Session:true
                                    | async as menuItems
                            "
                            class="body"
                        >
                            <app-menu-items-list
                                [menuItems]="menuItems"
                                [navigationEnd]="navigationEnd"
                            ></app-menu-items-list>

                            <hr />

                            <div class="sidebar-content">
                                For technical support please email
                                <!-- <b>TODO</b> -->
                            </div>
                            <!-- <img
                                class="zfImage"
                                src="../assets/images/Logo_ADOT_blue.png"
                            /> -->
                        </li>
                    </ul>
                </div>
            </div>

            <!-- #Menu -->
        </aside>
        <!-- #END# Left Sidebar -->
        <!-- Right Sidebar -->
        <aside id="rightsidebar" class="right-sidebar">
            <ul class="nav nav-tabs tab-nav-right" role="tablist">
                <li role="presentation">
                    <a href="#skins" data-toggle="tab" class="active">SKINS</a>
                </li>
                <li role="presentation">
                    <a href="#settings" data-toggle="tab">SETTINGS</a>
                </li>
            </ul>
            <div class="tab-content">
                <div
                    role="tabpanel"
                    class="tab-pane in active in active stretchLeft"
                    id="skins"
                >
                    <div class="demo-skin">
                        <div class="rightSetting">
                            <p>GENERAL SETTINGS</p>
                            <ul class="setting-list list-unstyled m-t-20">
                                <li>
                                    <div class="form-check">
                                        <div class="form-check m-l-10">
                                            <label class="form-check-label">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    checked
                                                />
                                                Save History
                                                <span class="form-check-sign">
                                                    <span class="check"></span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <div class="form-check m-l-10">
                                            <label class="form-check-label">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    checked
                                                />
                                                Show Status
                                                <span class="form-check-sign">
                                                    <span class="check"></span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <div class="form-check m-l-10">
                                            <label class="form-check-label">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    checked
                                                />
                                                Auto Submit Issue
                                                <span class="form-check-sign">
                                                    <span class="check"></span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="form-check">
                                        <div class="form-check m-l-10">
                                            <label class="form-check-label">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    checked
                                                />
                                                Show Status To All
                                                <span class="form-check-sign">
                                                    <span class="check"></span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="rightSetting">
                            <p>SIDEBAR MENU COLORS</p>
                            <button
                                type="button"
                                class="btn btn-sidebar-light btn-border-radius p-l-20 p-r-20"
                            >
                                Light
                            </button>
                            <button
                                type="button"
                                class="btn btn-sidebar-dark btn-default btn-border-radius p-l-20 p-r-20"
                            >
                                Dark
                            </button>
                            <button
                                type="button"
                                class="btn btn-sidebar-shsp btn-default btn-border-radius p-l-20 p-r-20"
                            >
                                SHSP
                            </button>
                        </div>
                        <div class="rightSetting">
                            <p>THEME COLORS</p>
                            <button
                                type="button"
                                class="btn btn-theme-light btn-border-radius p-l-20 p-r-20"
                            >
                                Light
                            </button>
                            <button
                                type="button"
                                class="btn btn-theme-dark btn-default btn-border-radius p-l-20 p-r-20"
                            >
                                Dark
                            </button>
                            <button
                                type="button"
                                class="btn btn-theme-shsp btn-default btn-border-radius p-l-20 p-r-20"
                            >
                                SHSP
                            </button>
                        </div>
                        <div class="rightSetting">
                            <p>SKINS</p>
                            <ul
                                class="demo-choose-skin choose-theme list-unstyled"
                            >
                                <li data-theme="black" class="actived">
                                    <div class="black-theme"></div>
                                </li>
                                <li data-theme="white">
                                    <div
                                        class="white-theme white-theme-border"
                                    ></div>
                                </li>
                                <li data-theme="purple">
                                    <div class="purple-theme"></div>
                                </li>
                                <li data-theme="blue">
                                    <div class="blue-theme"></div>
                                </li>
                                <li data-theme="cyan">
                                    <div class="cyan-theme"></div>
                                </li>
                                <li data-theme="green">
                                    <div class="green-theme"></div>
                                </li>
                                <li data-theme="orange">
                                    <div class="orange-theme"></div>
                                </li>
                            </ul>
                        </div>
                        <div class="rightSetting">
                            <p>Disk Space</p>
                            <div class="sidebar-progress">
                                <div class="progress m-t-20">
                                    <div
                                        class="progress-bar l-bg-cyan shadow-style width-per-45"
                                        role="progressbar"
                                        aria-valuenow="45"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                                <span class="progress-description">
                                    <small>26% remaining</small>
                                </span>
                            </div>
                        </div>
                        <div class="rightSetting m-b-15">
                            <p>Server Load</p>
                            <div class="sidebar-progress">
                                <div class="progress m-t-20">
                                    <div
                                        class="progress-bar l-bg-orange shadow-style width-per-63"
                                        role="progressbar"
                                        aria-valuenow="63"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                                <span class="progress-description">
                                    <small>Highly Loaded</small>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    role="tabpanel"
                    class="tab-pane stretchRight"
                    id="settings"
                >
                    <div class="demo-settings">
                        <p>GENERAL SETTINGS</p>
                        <ul class="setting-list">
                            <li>
                                <span>Report Panel Usage</span>
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" checked />
                                        <span
                                            class="lever switch-col-green"
                                        ></span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <span>Email Redirect</span>
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" />
                                        <span
                                            class="lever switch-col-blue"
                                        ></span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                        <p>SYSTEM SETTINGS</p>
                        <ul class="setting-list">
                            <li>
                                <span>Notifications</span>
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" checked />
                                        <span
                                            class="lever switch-col-purple"
                                        ></span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <span>Auto Updates</span>
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" checked />
                                        <span
                                            class="lever switch-col-cyan"
                                        ></span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                        <p>ACCOUNT SETTINGS</p>
                        <ul class="setting-list">
                            <li>
                                <span>Offline</span>
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" checked />
                                        <span
                                            class="lever switch-col-red"
                                        ></span>
                                    </label>
                                </div>
                            </li>
                            <li>
                                <span>Location Permission</span>
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" />
                                        <span
                                            class="lever switch-col-lime"
                                        ></span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </aside>
        <!-- #END# Right Sidebar -->
    </div>
</div>
<router-outlet></router-outlet>
