<div #element [ngSwitch]="childMenuItems" class="left-nav-menu-item-container">
    <!-- if no children -->
    <a
        *ngSwitchCase=""
        (click)="onClick(); $event.stopPropagation()"
        [ngClass]="{
            active: selectedMenuItems && selectedMenuItems.includes(menuItem)
        }"
    >
        <i
            *ngIf="
                menuItem.FontAwesomeIconName &&
                !menuItem.MaterialIconName &&
                !menuItem.IconFileName
            "
            class="fas fa-{{ menuItem.FontAwesomeIconName }}"
        >
        </i>

        <i
            *ngIf="
                !menuItem.FontAwesomeIconName &&
                menuItem.MaterialIconName &&
                !menuItem.IconFileName
            "
            class="material-icons"
        >
            {{ menuItem.MaterialIconName }}
        </i>

        <img
            *ngIf="
                !menuItem.FontAwesomeIconName &&
                !menuItem.MaterialIconName &&
                menuItem.IconFileName
            "
            src="../assets/images/icons/{{ menuItem.IconFileName }}"
        />

        <span>{{ menuItem.Title || menuItem.Name }}</span>
    </a>

    <!-- if has children -->
    <div *ngSwitchDefault>
        <a
            #parentMenuItemAnchor
            (click)="
                childMenuItems &&
                    childMenuItems.length &&
                    toggleCollapseMenuItem($event)
            "
            [ngClass]="{
                active:
                    selectedMenuItems && selectedMenuItems.includes(menuItem)
            }"
            [routerLink]=""
            class="menu-toggle"
        >
            <i
                *ngIf="
                    menuItem.FontAwesomeIconName &&
                    !menuItem.MaterialIconName &&
                    !menuItem.IconFileName
                "
                class="fas fa-{{ menuItem.FontAwesomeIconName }}"
            >
            </i>

            <i
                *ngIf="
                    !menuItem.FontAwesomeIconName &&
                    menuItem.MaterialIconName &&
                    !menuItem.IconFileName
                "
                class="material-icons"
            >
                {{ menuItem.MaterialIconName }}
            </i>

            <img
                *ngIf="
                    !menuItem.FontAwesomeIconName &&
                    !menuItem.MaterialIconName &&
                    menuItem.IconFileName
                "
                src="../assets/images/icons/{{ menuItem.IconFileName }}"
            />

            <span>{{ menuItem.Title || menuItem.Name }}</span>
        </a>
        <ul class="ml-menu">
            <li
                *ngFor="let childMenuItem of childMenuItems"
                routerLinkActive="active"
            >
                <app-left-nav-menu-item
                    [menuItem]="childMenuItem"
                    [parentMenuItem]="menuItem"
                    [selectedMenuItems]="selectedMenuItems"
                ></app-left-nav-menu-item>
            </li>
        </ul>
    </div>
</div>
